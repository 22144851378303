import _objectSpread from "/var/jenkins_home/workspace/frontend-web-shop/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
var dutyReg = /^[A-Za-z0-9]+$/;
import { AccountStorage } from "@services/account";
import { Countdown } from "@components/countdown";
var accountStorage = new AccountStorage();
var PayStatus = {
  WAIT: "待支付",
  PAYING: "支付中",
  SUCCESS: "支付成功",
  FAIL: "支付失败",
  CLOSED: "已取消",
  REFUNDED: "已退款"
};
export default {
  components: {
    Countdown: Countdown
  },
  data: function data() {
    return {
      isshowrule: false,
      payStatus: PayStatus,
      countdownshow: false,
      valueTime: "",
      data: {},
      list: [],
      info: {
        rise: null,
        duty: "",
        remark: ""
      },
      radio: "",
      radioList: [{
        id: 1,
        value: 1,
        name: "电子发票"
      }, {
        id: 2,
        value: 2,
        name: "纸质发票"
      }],
      popupStatus: false,
      isDownload: false,
      danger: false,
      dangerMessage: false,
      duty: false,
      // 税号校验
      dutyMessage: false,
      formList: [],
      checkedRows: [],
      productIds: [],
      userId: "",
      ids: [],
      selectAll: false,
      orderNumber: "",
      giftAmount: 0,
      arr: [] // 选中的文件数
      // isComponentModalActive: false
    };
  },
  filters: {
    statusName: function statusName(type) {
      switch (type) {
        case "01":
          return "待支付";
        case "02":
          return "待发货";
        case "03":
          return "待收货";
        case "04":
          return "交易完成";
        case "05":
          return "已取消";
      }
    },
    unit: function unit(type) {
      var a = type.indexOf("*");
      var num = type.substring(1, a) * type.substring(a + 1, type.length - 1);
      return num;
    },
    orderSource: function orderSource(type) {
      switch (type) {
        case "1":
          return "商城下单";
        case "2":
          return "erp同步";
      }
    },
    distributionWay: function distributionWay(type) {
      switch (type) {
        case "1":
          return "自有配送";
        case "2":
          return "京东配送";
        case "3":
          return "其他托运";
        case "4":
          return "仓库自提";
        case "5":
          return "门市自提";
      }
    },
    payWay: function payWay(type) {
      var statusObj = {
        ONLINE: "线上支付",
        ALIPAY: "支付宝支付",
        WXPAY: "微信支付",
        COD: "货到付款"
      };
      return statusObj[type];
    },
    invoice: function invoice(type) {
      var statusObj = {
        1: "是",
        2: "否"
      };
      return statusObj[type];
    }
  },
  created: function created() {
    var newUserId = accountStorage.getCacheUserInfo();
    this.userId = newUserId.userId;
    console.log(this.$route.query.orderInfo);
    this.orderNumber = JSON.parse(this.$route.query.orderInfo).orderNumber;
    this.giftAmount = JSON.parse(this.$route.query.orderInfo).giftAmount;
    this.getOrderDetail();
  },
  computed: {
    checkedAllSize: function checkedAllSize() {
      var _this = this;
      var val = 0;
      if (this.arr.length) {
        this.formList.forEach(function (item) {
          _this.arr.forEach(function (v) {
            if (item.pictureUrl === v.pictureUrl) {
              val += Number(item.pictureSize);
            }
          });
        });
      }
      return val.toFixed(1);
    },
    hasChecked: function hasChecked() {
      return this.formList.some(function (item) {
        return item.isCheckedName === true;
      });
    }
  },
  methods: {
    closerule: function closerule() {
      this.isshowrule = false;
    },
    showrule: function showrule() {
      this.isshowrule = true;
    },
    // 点击头部订单
    toBack: function toBack() {
      this.$router.push({
        name: "order",
        params: {
          status: "0"
        }
      });
    },
    stopEndTime: function stopEndTime() {
      this.getOrderDetail();
    },
    clearIconClick: function clearIconClick() {
      this.info.rise = null;
    },
    // 订单详情
    getOrderDetail: function getOrderDetail() {
      var _this2 = this;
      this.list = [];
      var url = "/pjyy-deepexi-order-center/api/v1/order/OcOrderLine/orderLineList?orderNumber=".concat(this.orderNumber);
      this.$getHttpClient().get(url).then(function (res) {
        _this2.data = res.data.data;
        var arr = [];
        arr = res.data.data.orderRebateDetailsVo;
        arr.forEach(function (item) {
          _this2.list.push(item);
        });
        _this2.orderId = res.data.data.id;
        _this2.valueTime = Math.ceil(_this2.data.closeTime / 1000);
        _this2.countdownshow = true;
      });
    },
    // 重新购买
    anewPurchase: function anewPurchase(item) {
      var _this3 = this;
      var arr = [];
      item.orderLines.forEach(function (ele) {
        arr.push(ele.productId);
      });
      var url = "/pjyy-deepexi-order-center/api/v1/cart/againAddGoodsToCartList";
      var data = {
        orderNumber: item.orderNumber
      };
      this.$getHttpClient().post(url, data).then(function (res) {
        if (res.data.code === "200") {
          _this3.$buefy.toast.open({
            message: "操作成功",
            type: "is-success"
          });
          _this3.getOrderDetail();
          _this3.$router.push({
            path: "/cart/cartList"
          });
        }
      });
    },
    // 再次购买
    againPurchase: function againPurchase(item) {
      var _this4 = this;
      var arr = [];
      item.orderLines.forEach(function (ele) {
        arr.push(ele.productId);
      });
      var url = "/pjyy-deepexi-order-center/api/v1/cart/againAddGoodsToCartList";
      var data = {
        orderNumber: item.orderNumber
      };
      this.$getHttpClient().post(url, data).then(function (res) {
        if (res.data.code === "200") {
          _this4.$buefy.toast.open({
            message: "操作成功",
            type: "is-success"
          });
          _this4.getOrderDetail();
          _this4.$router.push({
            path: "/cart/cartList"
          });
        }
      });
    },
    // 下载报告
    handleDownload: function handleDownload() {
      var _this5 = this;
      document.body.style.height = "".concat(document.documentElement.clientHeight, "px");
      document.body.style.overflow = "hidden";
      this.isDownload = true;
      this.list.forEach(function (item) {
        _this5.productIds.push(item.productId);
      });
      var productIds = this.productIds;
      var data = {
        productIds: productIds,
        orderid: this.data.id.toString()
      };
      var url = "/pjyy-deepexi-product-center/api/v1/product/drug/findAll";
      this.$getHttpClient().post(url, data).then(function (res) {
        if (res.data.code === "200") {
          _this5.formList = [];
          var arr = res.data.data;
          arr.forEach(function (item) {
            _this5.formList.push(_objectSpread({
              isCheckedName: false
            }, item));
          });
        }
      });
    },
    // 下载报告全选确定
    handleConfirmDownload: function handleConfirmDownload() {
      var _this6 = this;
      document.body.style.height = "";
      document.body.style.overflow = "";
      this.productIds = [];
      this.isDownload = false;
      // if (this.checkedAllSize > 20) {
      //   this.isComponentModalActive = true;
      //   this.selectAll = false;
      //   this.isDownload = false;
      //   return false;
      // }
      this.ids = [];
      var batchNumbers = [];
      this.formList.forEach(function (item) {
        if (item.isCheckedName) {
          _this6.ids.push(item.productId);
          batchNumbers.push(item.batchNumber);
        }
      });
      // process.env.VUE_APP_WEB_URL  获取当前请求的env文件配置
      var url = "".concat(process.env.VUE_APP_WEB_URL, "/pjyy-deepexi-product-center/api/v1/product/drug/downloadDrugPc?orderId=").concat(this.data.orderNumber, "&productIds=").concat(this.ids, "&batchNumber=").concat(batchNumbers, "&userId=").concat(this.getUserId());
      // window.open(url)
      location.href = url;
    },
    // 全选
    getSelectAll: function getSelectAll(val) {
      var _this7 = this;
      this.selectAll = val;
      this.arr = [];
      this.formList.forEach(function (item) {
        item.isCheckedName = val;
        if (item.isCheckedName) {
          _this7.arr.push(item);
        }
      });
    },
    // 单个勾选
    getSelectItem: function getSelectItem() {
      var arr = [];
      this.formList.forEach(function (item) {
        if (item.isCheckedName) {
          arr.push(item);
        }
      });
      this.arr = arr;
      this.selectAll = this.formList.every(function (item) {
        return item.isCheckedName === true;
      });
    },
    // 下载取消按钮
    handleCancelDownload: function handleCancelDownload() {
      document.body.style.height = "";
      document.body.style.overflow = "";
      this.isDownload = false;
      this.ids = [];
      this.productIds = [];
    },
    // 立即付款
    handlePayment: function handlePayment(orderNumber) {
      this.$router.push({
        name: "payMent",
        params: {
          orderId: orderNumber
        }
      });
    },
    //获取用户ID
    getUserId: function getUserId() {
      var userInfo = accountStorage.getCacheUserInfo();
      if (userInfo) {
        return userInfo.userId;
      }
      return;
    },
    // 加入购物车
    addCart: function addCart(item) {
      var _this8 = this;
      var URL = "/pjyy-deepexi-order-center/api/v1/cart/addGoodsToCartList";
      var data = {
        productId: Number(item.productId),
        userId: Number(this.getUserId()),
        totalNumber: item.totalNumber
      };
      this.$getHttpClient().post(URL, data).then(function (res) {
        if (res.data.code === "200") {
          _this8.$buefy.toast.open({
            message: "加入成功",
            type: "is-success"
          });
          _this8.$router.push({
            path: "/cart/cartList"
          });
        }
      });
    },
    blurDanger: function blurDanger() {
      if (this.info.rise === null) {
        this.danger = true;
        this.dangerMessage = true;
      } else {
        this.danger = false;
        this.dangerMessage = false;
      }
    },
    // 税号
    blurDuty: function blurDuty() {
      if (dutyReg.test(this.info.duty) && this.info.duty !== "") {
        this.duty = false;
        this.dutyMessage = false;
      } else {
        this.duty = true;
        this.dutyMessage = true;
      }
    },
    handleLoding: function handleLoding() {
      document.body.style.height = "".concat(document.documentElement.clientHeight, "px");
      document.body.style.overflow = "hidden";
      this.popupStatus = true;
    },
    handleCancel: function handleCancel() {
      document.body.style.height = "";
      document.body.style.overflow = "";
      this.popupStatus = false;
    },
    // 申请开票的确定按钮
    handleConfirm: function handleConfirm() {
      var _this9 = this;
      if (this.info.rise === null) {
        this.danger = true;
        this.dangerMessage = true;
        return;
      }
      if (dutyReg.test(this.info.duty) && this.info.duty !== "") {
        this.duty = false;
        this.dutyMessage = false;
      } else {
        this.duty = true;
        this.dutyMessage = true;
        return;
      }
      var url = "/pjyy-deepexi-order-center/api/v1/order/OcOrder/OrderInvoice";
      var data = {
        id: this.$route.params.id,
        invoiceName: this.info.rise,
        invoiceIdentify: this.info.duty,
        invoiceRemark: this.info.remark,
        invoiceType: String(this.radio)
      };
      this.$getHttpClient().post(url, data).then(function (res) {
        if (res.data.code === "200") {
          _this9.$buefy.toast.open({
            message: "申请开票成功",
            type: "is-success"
          });
        }
      });
      document.body.style.height = "";
      document.body.style.overflow = "";
      this.popupStatus = false;
    },
    // 取消订单
    updateOrderStatus: function updateOrderStatus(orderNumber) {
      var _this10 = this;
      var url = "/pjyy-deepexi-order-center/api/v1/order/OcOrder/updateOrderStatus05?orderNumber=".concat(orderNumber);
      this.$getHttpClient().get(url).then(function (res) {
        if (res.data.code === "200") {
          _this10.getOrderDetail();
        }
      });
    },
    // 跳转发票抬头
    handleManagement: function handleManagement() {
      this.$router.push({
        path: "invoice"
      });
    },
    // 跳转申请退货
    handleSales: function handleSales() {
      // let id = 1;
      // this.$router.push({
      //   path: `apply-goods?id=${id}`
      // });
    },
    // 售后退货
    handleSale: function handleSale(data) {
      this.$router.push({
        name: "salesDetail",
        params: {
          status: "1",
          orderNumber: data.orderNumber
        }
      });
    }
  },
  updated: function updated() {}
};