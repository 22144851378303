import "core-js/modules/es.number.constructor.js";
import { CountdownService } from "./countdown.service";
export default {
  data: function data() {
    return {
      time: ""
    };
  },
  props: {
    mode: {
      type: String,
      default: "standard"
    },
    value: {
      type: [Number, String],
      default: 0,
      required: true
    },
    format: {
      type: String
    }
  },
  created: function created() {
    var _this = this;
    if (this.value) {
      // 1小时的秒数
      var hoursToSeconds = 1 * 60 * 60;
      // 判断是否小于1小时
      var isLessThanOneHour = this.value < hoursToSeconds;
      var format = this.format;
      if (isLessThanOneHour) format = "m分s秒";
      new CountdownService({
        mode: this.mode,
        value: this.value,
        format: format,
        onStop: function onStop(_ref) {
          var time = _ref.time;
          _this.$emit("stop", time);
        },
        onChange: function onChange(_ref2) {
          var time = _ref2.time;
          _this.time = time;
          _this.$emit("change", time);
        }
      });
    }
  }
};