var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "data-download"
  }, [_c('div', {
    staticClass: "top"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("商品检验报告下载")]), _c('div', {
    staticClass: "search"
  }, [_c('label', {
    staticClass: "search-input-box"
  }, [_vm._m(0), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchVal,
      expression: "searchVal"
    }],
    staticClass: "input1",
    attrs: {
      "type": "text",
      "autocomplete": "off",
      "placeholder": "商品编号/商品名称/拼音首字母/生产厂家"
    },
    domProps: {
      "value": _vm.searchVal
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.searchVal = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "search-btn",
    on: {
      "click": _vm.onSearch
    }
  }, [_vm._v("搜索")])])]), _c('div', {
    staticClass: "content clearfix"
  }, [_c('ul', {
    staticClass: "product-list"
  }, _vm._l(_vm.list, function (v, i) {
    return _c('li', {
      key: i,
      staticClass: "product-item clearfix"
    }, [_c('div', {
      staticClass: "product"
    }, [_c('img', {
      attrs: {
        "src": v.mainImageUrl,
        "alt": "",
        "title": ""
      }
    }), _c('span', {
      staticClass: "name"
    }, [_vm._v("【" + _vm._s(v.specifications) + "】" + _vm._s(v.productName))])]), _c('div', {
      staticClass: "company"
    }, [_vm._v(_vm._s(v.produceFactory))]), _c('div', {
      staticClass: "medicine"
    }, [_vm._v(_vm._s(v.approvalNumber))]), _c('div', {
      staticClass: "operation"
    }, [_c('span', {
      on: {
        "click": function click($event) {
          return _vm.goDetail(i);
        }
      }
    }, [_vm._v("下载资料")])]), i == _vm.currentindex ? _c('div', {
      staticClass: "drug-items"
    }, _vm._l(v.pcDrugReportList, function (item, index) {
      return _c('div', {
        key: index,
        staticClass: "drug-item"
      }, [_c('p', [_vm._v("批次号：" + _vm._s(item.batchNumber))]), _c('font', {
        on: {
          "click": function click($event) {
            return _vm.goDetail1(item);
          }
        }
      }, [_vm._v("下载报告")])], 1);
    }), 0) : _vm._e()]);
  }), 0), _vm.list.length !== 0 ? _c('div', {
    staticClass: "pagination-box"
  }, [_c('b-pagination', {
    staticClass: "is-centered",
    attrs: {
      "total": _vm.total,
      "current": _vm.current,
      "per-page": _vm.perPage,
      "range-before": _vm.rangeBefore,
      "range-after": _vm.rangeAfter
    },
    on: {
      "update:current": function updateCurrent($event) {
        _vm.current = $event;
      },
      "change": _vm.handleChange
    }
  })], 1) : _vm._e()])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('span', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require("./images/icon_search.png")
    }
  })]);
}];
export { render, staticRenderFns };