var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('p', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "id": "headline"
    }
  }, [_vm._v("手机号")]), _c('div', {
    staticClass: "input-wrap"
  }, [_c('form', [_c('div', {
    staticClass: "inputBox"
  }, [_c('div', {
    staticClass: "iconfont",
    staticStyle: {
      "width": "390px",
      "height": "52px",
      "position": "relative"
    },
    attrs: {
      "id": "username"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.userInfo.phone,
      expression: "userInfo.phone"
    }],
    attrs: {
      "type": "text",
      "placeholder": "请输入手机号"
    },
    domProps: {
      "value": _vm.userInfo.phone
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.userInfo, "phone", $event.target.value);
      }
    }
  })]), _c('p', {
    staticStyle: {
      "text-align": "left",
      "margin-top": "24px"
    },
    attrs: {
      "id": "headline"
    }
  }, [_vm._v("验证码")]), [_c('div', {
    staticClass: "iconfont",
    staticStyle: {
      "width": "390px",
      "height": "52px",
      "position": "relative"
    },
    attrs: {
      "id": "password-2"
    }
  }, [_c('div', {
    staticClass: "checkCode"
  }, [!_vm.showTime ? _c('span', {
    staticClass: "getMsg getMsgStyle",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.getVerifyCode();
      }
    }
  }, [_vm._v("获取验证码")]) : _vm._e(), _vm.showTime ? _c('span', {
    staticClass: "getMsg showTime"
  }, [_vm._v(_vm._s(_vm.time) + "s后重新获取")]) : _vm._e()]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.userInfo.verifyCode,
      expression: "userInfo.verifyCode"
    }],
    attrs: {
      "type": "text",
      "placeholder": "请输入短信验证码"
    },
    domProps: {
      "value": _vm.userInfo.verifyCode
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.userInfo, "verifyCode", $event.target.value);
      }
    }
  })])], _c('div', {
    staticClass: "buttons"
  }, [_c('b-button', {
    staticClass: "login-btn",
    attrs: {
      "type": "is-info",
      "disabled": _vm.isDisabled,
      "expanded": ""
    },
    on: {
      "click": _vm.onLogin
    }
  }, [_vm._v("登录 ")])], 1), _c('div', {
    staticClass: "enroll",
    on: {
      "click": _vm.loginIndexShow
    }
  }, [_vm._v("立即注册")])], 2)])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };