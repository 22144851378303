import _objectSpread from "/var/jenkins_home/workspace/frontend-web-shop/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { genderList } from "./sales-mock";
export default {
  components: {},
  data: function data() {
    return {
      isSales: false,
      //退货原因
      isDetail: "",
      // 是否显示退货单号。。。
      info: {
        linkman: "",
        // 联系人
        phone: "",
        // 联系电话
        sales: null,
        // 退货原因
        remark: "" // 备注
      },
      genderList: genderList,
      cartList: [],
      selectAll: false,
      returnGoodsDetails: [],
      isSuccess: false,
      SalesDetailList: [],
      // 退货详情
      id: ""
    };
  },
  filters: {
    statusName: function statusName(type) {
      var statusObj = {
        1: "待审核",
        2: "已审核",
        3: "已拒绝"
      };
      return statusObj[type];
    }
  },
  computed: {
    // 是否禁用提交按钮
    hasChecked: function hasChecked() {
      return this.cartList.some(function (item) {
        return item.isCheckedName === true;
      });
    }
  },
  created: function created() {
    this.id = this.$route.params.orderNumber;
    this.isDetail = this.$route.params.status;
    if (this.isDetail === "1") {
      this.getOrderDetail();
    } else {
      this.getSalesDetail();
    }
  },
  methods: {
    // 退货单详情
    getSalesDetail: function getSalesDetail() {
      var _this = this;
      var url = "/pjyy-deepexi-order-center/api/v1/order/OcOrderAfter/returnGoodsDetail?afterId=".concat(this.id);
      this.$getHttpClient().get(url).then(function (res) {
        var arr = [];
        _this.SalesDetailList = res.data.data;
        _this.info.linkman = _this.SalesDetailList.contactPeople;
        _this.info.phone = _this.SalesDetailList.contactPhone;
        _this.info.sales = _this.SalesDetailList.applyReason;
        _this.info.remark = _this.SalesDetailList.remark;
        arr = res.data.data.orderAfterLineVoList;
        arr.forEach(function (item) {
          item.orderAfterLineBatchVoList.forEach(function (el) {
            _this.cartList.push(_objectSpread({
              specifications: item.specifications,
              produceFactory: item.produceFactory,
              productName: item.productName,
              productPhoto: item.productPhoto,
              productIds: item.productId
            }, el));
          });
        });
      });
    },
    // 提交创建退货单
    salesDetailList: function salesDetailList() {
      var _this2 = this;
      var url = "/pjyy-deepexi-order-center/api/v1/order/OcOrderAfter/returnGoods";
      var arr = [];
      this.cartList.forEach(function (item) {
        if (item.isCheckedName) {
          arr.push({
            productId: item.productId,
            //商品id
            productCode: item.productCode,
            //商品code
            number: item.salesNum,
            //退货数量
            batchNumber: item.batchNumber,
            //批次号
            productionBatchNumber: item.productionBatchNumber,
            // 生产批号
            erpOrderLineNum: item.erpOrderLineNum
          });
        }
      });
      var data = {
        orderNumber: this.id,
        contactPeople: this.info.linkman,
        contactPhone: this.info.phone,
        applyReason: this.info.sales,
        remark: this.info.remark,
        returnGoodsDetails: [].concat(arr)
      };
      this.$getHttpClient({
        useResponseToast: false
      }).post(url, data).then(function (res) {
        if (res.data.code === "100002") {
          _this2.$buefy.toast.open({
            message: res.data.msg,
            type: "is-danger"
          });
        }
        if (res.data.code === "200") {
          _this2.$router.push({
            name: "after-sale"
          });
        }
      });
    },
    // 商品列表
    getOrderDetail: function getOrderDetail() {
      var _this3 = this;
      var url = "/pjyy-deepexi-order-center/api/v1/order/OcOrderLine/orderLineList?orderNumber=".concat(this.id);
      this.$getHttpClient().get(url).then(function (res) {
        var arr = [];
        arr = res.data.data.orderLines;
        arr.forEach(function (item) {
          if (item.orderLineBatchVos.length != 0) {
            item.orderLineBatchVos.forEach(function (ele) {
              _this3.cartList.push(_objectSpread({
                productName: item.productName,
                produceFactory: item.produceFactory,
                specifications: item.specifications,
                isCheckedName: false,
                productId: item.productId,
                productCode: item.productCode,
                salesNum: 0
              }, ele));
            });
          } else {
            _this3.cartList.push(_objectSpread(_objectSpread({}, item), {}, {
              batchDeliveryNumber: 0,
              batchApplyReturnNumber: 0,
              batchAlreadyReturnNumber: 0
            }));
          }
        });
      });
    },
    // 单个勾选
    getSelectItem: function getSelectItem() {
      var arr = [];
      this.cartList.forEach(function (item) {
        var num = item.batchDeliveryNumber - item.batchApplyReturnNumber - item.batchAlreadyReturnNumber;
        if (num != 0) {
          arr.push(item);
        }
      });
      this.selectAll = arr.every(function (item) {
        return item.isCheckedName === true;
      });
    },
    input: function input() {},
    // 全选
    getSelectAll: function getSelectAll(val) {
      this.selectAll = val;
      this.cartList.forEach(function (item) {
        var num = item.batchDeliveryNumber - item.batchApplyReturnNumber - item.batchAlreadyReturnNumber;
        if (num != 0) {
          item.isCheckedName = val;
        }
      });
    },
    // 提交
    toSubmit: function toSubmit() {
      if (this.info.sales === null) {
        return this.$buefy.toast.open({
          message: "请填写退货原因",
          type: "is-danger"
        });
      }
      var arr = [];
      this.cartList.forEach(function (item) {
        if (item.isCheckedName) {
          arr.push(item);
        }
      });
      var flag = arr.every(function (item) {
        return item.salesNum != 0;
      });
      if (!flag) {
        return this.$buefy.toast.open({
          message: "请填写退货数量",
          type: "is-danger"
        });
      } else {
        this.salesDetailList();
      }
    },
    onBlur: function onBlur() {
      this.cartList.forEach(function (item) {
        if (item.salesNum > item.batchDeliveryNumber - item.batchApplyReturnNumber - item.batchAlreadyReturnNumber) {
          item.salesNum = 0;
        }
      });
    },
    // 退货后退
    toBack: function toBack() {
      window.history.go(-1);
    },
    // 联系人input
    linkmanBlur: function linkmanBlur() {},
    // 电话input
    phoneBlur: function phoneBlur() {},
    // 退货原因select
    selecGenderChange: function selecGenderChange() {},
    // 备注input
    remarkBlur: function remarkBlur() {},
    // 清空联系人
    emptyLinkman: function emptyLinkman() {
      this.info.linkman = "";
    },
    // 清空手机号
    emptyPhone: function emptyPhone() {
      this.info.phone = "";
    },
    // 清空备注
    emptyremark: function emptyremark() {
      this.info.remark = "";
    },
    // 判断数量++ 不能大于可退数量
    maxkcNumber: function maxkcNumber(val) {
      var num = null;
      if ((val.totalNumber + "").length >= 6) {
        return val.totalNumber = 9999;
      }
      if (Number(val.salesNum) > Number(val.batchDeliveryNumber - val.batchApplyReturnNumber - val.batchAlreadyReturnNumber)) {
        num = val.batchDeliveryNumber - val.batchApplyReturnNumber - val.batchAlreadyReturnNumber;
      } else {
        num = val.batchDeliveryNumber - val.batchApplyReturnNumber - val.batchAlreadyReturnNumber;
      }
      return parseInt(num);
    },
    // 取消
    handleCancel: function handleCancel() {
      window.history.go(-1);
    },
    toDetail: function toDetail(item) {
      this.$router.push({
        name: "details",
        params: {
          id: item.productIds || item.productId,
          sale: item.notLowerSale
        }
      });
    },
    toOrderDetail: function toOrderDetail(orderNumber) {
      this.$router.push({
        path: "/user-center/order-detail/".concat(orderNumber)
      });
    }
  },
  updated: function updated() {}
};