import "core-js/modules/es.string.trim.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "sales"
  }, [_c('div', {
    staticClass: "sales-detail-title"
  }, [_c('span', {
    on: {
      "click": _vm.toBack
    }
  }, [_vm._v("退货")])]), (_vm.isDetail === '1' ? false : true) ? _c('div', {
    staticClass: "sales-title"
  }, [_c('p', [_vm._v(" 退货单号: "), _c('span', [_vm._v(_vm._s(_vm.SalesDetailList.afterNumber))])]), _c('p', [_vm._v(" 关联订单号: "), _c('span', {
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function click($event) {
        return _vm.toOrderDetail(_vm.SalesDetailList.orderNumber);
      }
    }
  }, [_vm._v(_vm._s(_vm.SalesDetailList.orderNumber))])]), _c('p', [_vm._v(" 退货品规数: "), _c('span', [_vm._v(_vm._s(_vm.SalesDetailList.categoryNumber))])]), _c('p', [_vm._v(" 退货数量: "), _c('span', [_vm._v(_vm._s(_vm.SalesDetailList.totalNumber))])]), _c('p', [_vm._v(" 退货状态: "), _c('span', [_vm._v(_vm._s(_vm._f("statusName")(_vm.SalesDetailList.status)))])]), _c('p', [_vm._v(" 退款金额: "), _vm.SalesDetailList.status == '1' ? _c('span', [_vm._v("--")]) : _c('span', [_vm._v("¥ " + _vm._s(_vm.SalesDetailList.totalMoney))])])]) : _vm._e(), _c('div', {
    staticClass: "form-data"
  }, [_c('div', {
    staticClass: "form"
  }, [_c('div', {
    staticClass: "field is-horizontal"
  }, [_c('label', {
    staticClass: "label"
  }, [_vm._v("联系人:")]), _c('div', {
    staticClass: "control"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.info.linkman,
      expression: "info.linkman",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "input",
    attrs: {
      "placeholder": "请输入",
      "maxlength": "10",
      "disabled": _vm.isDetail === '2'
    },
    domProps: {
      "value": _vm.info.linkman
    },
    on: {
      "blur": [_vm.linkmanBlur, function ($event) {
        return _vm.$forceUpdate();
      }],
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.info, "linkman", $event.target.value.trim());
      }, _vm.linkmanBlur]
    }
  }), _c('span', {
    staticClass: "close",
    on: {
      "click": _vm.emptyLinkman
    }
  }, [_c('img', {
    attrs: {
      "src": require("./images/icon_close.png"),
      "alt": ""
    }
  })])])]), _c('div', {
    staticClass: "field is-horizontal"
  }, [_c('label', {
    staticClass: "label"
  }, [_vm._v("联系电话:")]), _c('div', {
    staticClass: "control"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.info.phone,
      expression: "info.phone",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "input",
    attrs: {
      "placeholder": "请输入",
      "maxlength": "11",
      "disabled": _vm.isDetail === '2'
    },
    domProps: {
      "value": _vm.info.phone
    },
    on: {
      "blur": [_vm.phoneBlur, function ($event) {
        return _vm.$forceUpdate();
      }],
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.info, "phone", $event.target.value.trim());
      }, _vm.phoneBlur]
    }
  }), _c('span', {
    staticClass: "close",
    on: {
      "click": _vm.emptyPhone
    }
  }, [_c('img', {
    attrs: {
      "src": require("./images/icon_close.png"),
      "alt": ""
    }
  })])])]), _c('div', {
    staticClass: "message-Gender"
  }, [_c('b-field', {
    attrs: {
      "label": "退货原因:"
    }
  }, [_c('b-select', {
    attrs: {
      "placeholder": "请选择",
      "disabled": _vm.isDetail === '2'
    },
    on: {
      "input": _vm.selecGenderChange,
      "blur": _vm.selecGenderChange
    },
    model: {
      value: _vm.info.sales,
      callback: function callback($$v) {
        _vm.$set(_vm.info, "sales", $$v);
      },
      expression: "info.sales"
    }
  }, _vm._l(_vm.genderList, function (item) {
    return _c('option', {
      key: item.id,
      domProps: {
        "value": item.value
      }
    }, [_vm._v(" " + _vm._s(item.value) + " ")]);
  }), 0)], 1)], 1), _c('div', {
    staticClass: "field is-horizontal"
  }, [_c('label', {
    staticClass: "label"
  }, [_vm._v("备注:")]), _c('div', {
    staticClass: "control"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.info.remark,
      expression: "info.remark",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "input",
    attrs: {
      "placeholder": "请输入",
      "maxlength": "50",
      "disabled": _vm.isDetail === '2'
    },
    domProps: {
      "value": _vm.info.remark
    },
    on: {
      "blur": [_vm.remarkBlur, function ($event) {
        return _vm.$forceUpdate();
      }],
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.info, "remark", $event.target.value.trim());
      }, _vm.remarkBlur]
    }
  }), _c('span', {
    staticClass: "close",
    on: {
      "click": _vm.emptyremark
    }
  }, [_c('img', {
    attrs: {
      "src": require("./images/icon_close.png"),
      "alt": ""
    }
  })])])])])]), _c('div', {
    staticClass: "sales-content"
  }, [_c('div', {
    staticClass: "sales-content-title"
  }, [_vm._v("退货商品")]), _c('div', {
    staticClass: "product-list-box"
  }, [_c('div', {
    staticClass: "product-columns"
  }, [_c('p', {
    staticClass: "columns-commodity"
  }, [_vm._v("商品")]), _c('p', {
    staticClass: "columns-inventory"
  }, [_vm._v("库存批次号")]), _c('p', {
    staticClass: "columns-manufacture"
  }, [_vm._v("生产批号")]), _vm.isDetail === '1' ? _c('p', {
    staticClass: "columns-can"
  }, [_vm._v("可退数量")]) : _vm._e(), _c('p', {
    staticClass: "columns-sales"
  }, [_vm._v("退货数量")])]), _c('div', {
    staticClass: "shopp-product-list-box"
  }, _vm._l(_vm.cartList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "shopp-product"
    }, [_vm.isDetail === '2' ? _c('div', {
      staticClass: "check"
    }) : _vm._e(), (_vm.isDetail === '1' ? true : false) ? _c('b-checkbox', {
      attrs: {
        "type": "is-info",
        "disabled": item.batchDeliveryNumber - item.batchApplyReturnNumber - item.batchAlreadyReturnNumber === 0
      },
      on: {
        "input": function input($event) {
          return _vm.getSelectItem($event, item);
        }
      },
      model: {
        value: item.isCheckedName,
        callback: function callback($$v) {
          _vm.$set(item, "isCheckedName", $$v);
        },
        expression: "item.isCheckedName"
      }
    }) : _vm._e(), _c('div', {
      staticClass: "product-info",
      on: {
        "click": function click($event) {
          return _vm.toDetail(item);
        }
      }
    }, [_c('div', {
      staticClass: "product-info-main"
    }, [_c('div', {
      class: _vm.isDetail === '2' ? 'md-product-introduce' : 'product-introduce'
    }, [_c('p', [_vm._v("【" + _vm._s(item.specifications) + "】" + _vm._s(item.productName))]), _c('p', [_vm._v(_vm._s(item.produceFactory))])])])]), _c('div', {
      staticClass: "product-inventory"
    }, [_c('p', [_vm._v(_vm._s(item.batchNumber))])]), _c('div', {
      class: _vm.isDetail === '2' ? 'md-product-manufacture' : 'product-manufacture'
    }, [_c('p', [_vm._v(_vm._s(item.productionBatchNumber))])]), _vm.isDetail === '1' ? _c('div', {
      staticClass: "product-can"
    }, [_c('p', [_vm._v(" " + _vm._s(item.batchDeliveryNumber - item.batchApplyReturnNumber - item.batchAlreadyReturnNumber) + " ")])]) : _vm._e(), _vm.isDetail === '1' ? _c('div', {
      staticClass: "product-sales"
    }, [_c('div', [_c('b-field', [_c('b-numberinput', {
      attrs: {
        "size": "is-small",
        "min": "0",
        "placeholder": "0",
        "max": _vm.maxkcNumber(item)
      },
      on: {
        "blur": function blur($event) {
          return _vm.onBlur(item, index);
        },
        "input": function input($event) {
          return _vm.input(item);
        }
      },
      model: {
        value: item.salesNum,
        callback: function callback($$v) {
          _vm.$set(item, "salesNum", $$v);
        },
        expression: "item.salesNum"
      }
    })], 1)], 1)]) : _vm._e(), _vm.isDetail === '2' ? _c('div', {
      staticClass: "product-sales"
    }, [_c('div', [_c('b-field', [_c('b-numberinput', {
      attrs: {
        "size": "is-small",
        "min": "0",
        "placeholder": "0",
        "max": _vm.maxkcNumber(item),
        "disabled": true,
        "controls": false
      },
      on: {
        "blur": function blur($event) {
          return _vm.onBlur(item, index);
        },
        "input": function input($event) {
          return _vm.input($event, item);
        }
      },
      model: {
        value: item.totalNumber,
        callback: function callback($$v) {
          _vm.$set(item, "totalNumber", $$v);
        },
        expression: "item.totalNumber"
      }
    })], 1)], 1)]) : _vm._e()], 1);
  }), 0)])]), (_vm.isDetail === '1' ? true : false) ? _c('div', {
    staticClass: "settlement-active-box"
  }, [_c('div', {
    staticClass: "left-box"
  }, [_vm.cartList.length != 0 ? _c('p', [_c('b-checkbox', {
    attrs: {
      "size": "is-small",
      "type": "is-info"
    },
    on: {
      "input": _vm.getSelectAll
    },
    model: {
      value: _vm.selectAll,
      callback: function callback($$v) {
        _vm.selectAll = $$v;
      },
      expression: "selectAll"
    }
  }, [_vm._v("全选")])], 1) : _vm._e()])]) : _vm._e(), (_vm.isDetail === '1' ? false : true) ? _c('div', {
    staticClass: "running"
  }, [_c('div', {
    staticClass: "running-title"
  }, [_vm._v("退款流水信息")]), _c('div', {
    staticClass: "running-content"
  }, [_c('p', [_vm._v(" 退款流水号: "), _c('span', [_vm._v(_vm._s(_vm.SalesDetailList.refundAt))])]), _c('p', [_vm._v(" 退款时间: "), _c('span', [_vm._v(_vm._s(_vm.SalesDetailList.refundWaterNumber))])])])]) : _vm._e(), _c('div', {
    staticClass: "a-buttons"
  }, [(_vm.isDetail === '1' ? true : false) ? _c('span', {
    staticClass: "form-cancel",
    on: {
      "click": _vm.handleCancel
    }
  }, [_vm._v("取消")]) : _vm._e(), (_vm.isDetail === '1' ? true : false) ? _c('b-button', {
    staticClass: "submit-button",
    attrs: {
      "disabled": !_vm.hasChecked
    },
    on: {
      "click": _vm.toSubmit
    }
  }, [_vm._v("提交")]) : _vm._e(), (_vm.isDetail === '1' ? false : true) ? _c('span', {
    staticClass: "form-cancel",
    on: {
      "click": _vm.handleCancel
    }
  }, [_vm._v("返回")]) : _vm._e()], 1), _vm.isSuccess ? _c('div', {
    staticClass: "success-bg"
  }, [_c('div', {
    staticClass: "success"
  }, [_vm._v("请选择退货数量")])]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };