var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "data-download"
  }, [_c('div', {
    staticClass: "top"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("商品资料下载")]), _c('div', {
    staticClass: "search"
  }, [_c('label', {
    staticClass: "search-input-box"
  }, [_vm._m(0), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchVal,
      expression: "searchVal"
    }],
    staticClass: "input1",
    attrs: {
      "type": "text",
      "autocomplete": "off",
      "placeholder": "商品编号/商品名称/拼音首字母/生产厂家"
    },
    domProps: {
      "value": _vm.searchVal
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.searchVal = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "search-btn",
    on: {
      "click": _vm.onSearch
    }
  }, [_vm._v("搜索")])])]), _c('div', {
    staticClass: "content clearfix"
  }, [_c('ul', {
    staticClass: "product-list"
  }, _vm._l(_vm.list, function (v, i) {
    return _c('li', {
      key: i,
      staticClass: "product-item clearfix"
    }, [_c('div', {
      staticClass: "product"
    }, [_c('img', {
      attrs: {
        "src": v.mainImageUrl,
        "alt": "",
        "title": ""
      }
    }), v.prescription + '' === '8906' ? _c('span', {
      staticClass: "tag OTC-green"
    }, [_vm._v("OTC")]) : _vm._e(), v.prescription + '' === '8905' ? _c('span', {
      staticClass: "tag OTC-red"
    }, [_vm._v("OTC")]) : _vm._e(), v.prescription + '' === '8904' ? _c('span', {
      staticClass: "tag RX-red"
    }, [_vm._v("RX")]) : _vm._e(), _c('span', {
      staticClass: "name"
    }, [_vm._v("【" + _vm._s(v.specifications) + "】" + _vm._s(v.productName))])]), _c('div', {
      staticClass: "company"
    }, [_vm._v(_vm._s(v.produceFactory))]), _c('div', {
      staticClass: "medicine"
    }, [_vm._v(_vm._s(v.approvalNumber))]), _c('div', {
      staticClass: "operation"
    }, [_c('span', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: v.isShowDown,
        expression: "v.isShowDown"
      }],
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.goDetail(v);
        }
      }
    }, [_vm._v("下载资料")]), _c('span', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: v.downType == 0 || v.speed == 100,
        expression: "v.downType == 0 || v.speed == 100"
      }],
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.goDetail(v);
        }
      }
    }, [_vm._v("重新下载")]), _c('span', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: v.downType == 1 && v.speed == 100,
        expression: "v.downType == 1 && v.speed == 100"
      }],
      staticStyle: {
        "color": "#48c78e",
        "margin-left": "5px"
      }
    }, [_vm._v("下载完成")]), _c('span', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: v.downType == 0,
        expression: "v.downType == 0"
      }],
      staticStyle: {
        "color": "#cccccc",
        "margin-left": "5px"
      }
    }, [_vm._v("下载失败")]), _c('span', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: v.speed != undefined && v.speed < 100 && v.downType == 1,
        expression: "v.speed != undefined && v.speed < 100 && v.downType == 1"
      }]
    }, [_vm._v("下载中")])]), _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: v.speed > 0 && v.speed <= 100 && v.downType == 1,
        expression: "v.speed > 0 && v.speed <= 100 && v.downType == 1"
      }],
      staticClass: "progressBox"
    }, [_c('b-progress', {
      attrs: {
        "type": "is-success",
        "value": v.speed,
        "show-value": "",
        "format": "percent"
      }
    })], 1)]);
  }), 0), _vm.list.length !== 0 ? _c('div', {
    staticClass: "pagination-box"
  }, [_c('b-pagination', {
    staticClass: "is-centered",
    attrs: {
      "total": _vm.total,
      "current": _vm.current,
      "per-page": _vm.perPage,
      "range-before": _vm.rangeBefore,
      "range-after": _vm.rangeAfter
    },
    on: {
      "update:current": function updateCurrent($event) {
        _vm.current = $event;
      },
      "change": _vm.handleChange
    }
  })], 1) : _vm._e()])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('span', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require("./images/icon_search.png")
    }
  })]);
}];
export { render, staticRenderFns };