import "core-js/modules/es.math.trunc.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/web.dom-collections.for-each.js";
export default {
  components: {},
  data: function data() {
    return {
      countdownshow: false,
      valueTime: "",
      data: [],
      list: [],
      orderBatchVoList: [],
      info: {
        rise: null,
        duty: "",
        remark: ""
      },
      radio: "",
      radioList: [{
        id: 1,
        value: 1,
        name: "电子发票"
      }, {
        id: 2,
        value: 2,
        name: "纸质发票"
      }],
      popupStatus: false,
      isDownload: false,
      danger: false,
      dangerMessage: false,
      duty: false,
      // 税号校验
      dutyMessage: false,
      formList: [],
      checkedRows: [],
      productIds: [],
      batchNumber: ""
    };
  },
  filters: {
    statusName: function statusName(type) {
      switch (type) {
        case "1":
          return "退货退款";
        case "2":
          return "换货";
        case "3":
          return "退款不退货";
      }
    },
    NumFormat: function NumFormat(value) {
      if (!value) return "0.00";
      value = value.toFixed(2);
      var intPart = Math.trunc(value); // 获取整数部分
      var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,"); // 将整数部分逢三一断
      var floatPart = ".00"; // 预定义小数部分
      var value2Array = value.split(".");
      // =2表示数据有小数位
      if (value2Array.length === 2) {
        floatPart = value2Array[1].toString(); // 拿到小数部分
        if (floatPart.length === 1) {
          // 补0,实际上用不着
          return intPartFormat + "." + floatPart + "0";
        } else {
          return intPartFormat + "." + floatPart;
        }
      } else {
        return intPartFormat + floatPart;
      }
    }
  },
  created: function created() {
    this.getOrderDetail();
  },
  methods: {
    // 订单详情
    getOrderDetail: function getOrderDetail() {
      var _this = this;
      var url = "/pjyy-deepexi-order-center/api/v1/order/OcOrderAfterLine/orderAfterLineList";
      var data = {
        page: this.current,
        pageSize: this.perPage,
        status: this.status,
        afterId: this.$route.params.id
      };
      this.$getHttpClient().post(url, data).then(function (res) {
        _this.total = res.data.data.totalElements;
        _this.perPage = res.data.data.size;
        _this.data = res.data.data.content[0];
        _this.list = res.data.data.content[0].orderAfterLineVoList;
        _this.list.forEach(function (item) {
          _this.orderBatchVoList = item.orderAfterLineBatchVoList;
        });
      });
    }
  },
  updated: function updated() {}
};