import "core-js/modules/es.string.trim.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "user-message"
  }, [_c('div', {
    staticClass: "user-message-title"
  }, [_vm._v("个人中心/基本信息")]), _c('div', {
    staticClass: "user-message-wrap"
  }, [_vm._m(0), _c('div', {
    staticClass: "message-right"
  }, [_c('p', [_vm._v(_vm._s(_vm.isExternal ? '---' : _vm.userInfo.loginCompanyName))]), _c('p', [_vm._v(_vm._s(_vm.isExternal ? '---' : _vm.userInfo.companyTypeName))]), _c('p', [_vm._v(_vm._s(_vm.isExternal ? '---' : _vm.userInfo.companyAddr))]), _c('p', [_vm._v(" " + _vm._s(_vm.userInfo.account) + " "), _c('span', {
    on: {
      "click": _vm.handleAmend
    }
  }, [_vm._v("修改密码")])]), !_vm.isName ? _c('p', [_vm._v(" " + _vm._s(_vm.userInfo.userName) + " ")]) : _vm._e(), _vm.isName ? _c('div', {
    staticClass: "message-input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.userInfo.userName,
      expression: "userInfo.userName"
    }],
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.userInfo.userName
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.userInfo, "userName", $event.target.value);
      }
    }
  }), _c('span', {
    on: {
      "click": _vm.handleConfirm
    }
  }, [_vm._v("确定")])]) : _vm._e(), !_vm.isGender ? _c('p', {
    staticClass: "gender"
  }, [_vm._v(" " + _vm._s(_vm.isExternal ? '---' : _vm.userInfo.genderValue) + " ")]) : _vm._e(), _vm.isGender ? _c('div', {
    staticClass: "message-Gender"
  }, [_c('b-select', {
    attrs: {
      "placeholder": "请选择"
    },
    on: {
      "input": _vm.selecGenderChange,
      "blur": _vm.selecGenderChange
    },
    model: {
      value: _vm.userInfo.genderValue,
      callback: function callback($$v) {
        _vm.$set(_vm.userInfo, "genderValue", $$v);
      },
      expression: "userInfo.genderValue"
    }
  }, _vm._l(_vm.genderList, function (item) {
    return _c('option', {
      key: item.id,
      domProps: {
        "value": item.value
      }
    }, [_vm._v(" " + _vm._s(item.value) + " ")]);
  }), 0)], 1) : _vm._e(), !_vm.isPhone ? _c('p', [_vm._v(" " + _vm._s(_vm.userInfo.phone) + " ")]) : _vm._e(), _vm.isPhone ? _c('div', {
    staticClass: "message-phone"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.userInfo.phone,
      expression: "userInfo.phone"
    }],
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.userInfo.phone
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.userInfo, "phone", $event.target.value);
      }
    }
  }), _c('span', {
    on: {
      "click": _vm.handleConfirmPhone
    }
  }, [_vm._v("确定")])]) : _vm._e()])]), _vm.popupStatus ? _c('div', {
    staticClass: "loding"
  }, [_c('div', {
    staticClass: "loding-wrap"
  }, [_c('div', {
    staticClass: "loding-title"
  }, [_vm._v("修改密码")]), _c('div', {
    staticClass: "form"
  }, [_c('div', {
    staticClass: "field is-horizontal"
  }, [_c('label', {
    staticClass: "label"
  }, [_vm._v("原密码")]), _c('div', {
    staticClass: "control"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.infoPassword.original,
      expression: "infoPassword.original",
      modifiers: {
        "trim": true
      }
    }],
    class: [{
      'is-danger': _vm.isOriginal
    }, 'input'],
    attrs: {
      "placeholder": "请输入",
      "maxlength": "32"
    },
    domProps: {
      "value": _vm.infoPassword.original
    },
    on: {
      "blur": [_vm.oldPwdBlur, function ($event) {
        return _vm.$forceUpdate();
      }],
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.infoPassword, "original", $event.target.value.trim());
      }, _vm.oldPwdBlur]
    }
  }), _c('span', {
    staticClass: "close",
    on: {
      "click": _vm.emptyOriginal
    }
  }, [_c('img', {
    attrs: {
      "src": require("./images/icon_close.png"),
      "alt": ""
    }
  })])]), _vm.isOriginal ? _c('p', {
    staticClass: "help is-danger"
  }, [_vm._v(" " + _vm._s(_vm.originalErrorTxt) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "field is-horizontal"
  }, [_c('label', {
    staticClass: "label"
  }, [_vm._v("新密码")]), _c('div', {
    staticClass: "control"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.infoPassword.newPassword,
      expression: "infoPassword.newPassword",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "input",
    attrs: {
      "placeholder": "请输入",
      "maxlength": "32"
    },
    domProps: {
      "value": _vm.infoPassword.newPassword
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.infoPassword, "newPassword", $event.target.value.trim());
      },
      "blur": function blur($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _c('span', {
    staticClass: "close",
    on: {
      "click": _vm.newPasswordBtn
    }
  }, [_c('img', {
    attrs: {
      "src": require("./images/icon_close.png"),
      "alt": ""
    }
  })])])]), _c('div', {
    staticClass: "field is-horizontal"
  }, [_c('label', {
    staticClass: "label"
  }, [_vm._v("确认新密码")]), _c('div', {
    staticClass: "control"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.infoPassword.affirmPassword,
      expression: "infoPassword.affirmPassword",
      modifiers: {
        "trim": true
      }
    }],
    class: [{
      'is-danger': _vm.isAffirmPassword
    }, 'input'],
    attrs: {
      "placeholder": "请输入",
      "maxlength": "32"
    },
    domProps: {
      "value": _vm.infoPassword.affirmPassword
    },
    on: {
      "blur": [_vm.blurAffirmPassword, function ($event) {
        return _vm.$forceUpdate();
      }],
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.infoPassword, "affirmPassword", $event.target.value.trim());
      }
    }
  }), _c('span', {
    staticClass: "close",
    on: {
      "click": _vm.affirmPasswordBtn
    }
  }, [_c('img', {
    attrs: {
      "src": require("./images/icon_close.png"),
      "alt": ""
    }
  })])]), _vm.isAffirmPassword ? _c('p', {
    staticClass: "help is-danger"
  }, [_vm._v("确认密码不一致")]) : _vm._e()])]), _c('div', {
    staticClass: "buttons"
  }, [_c('span', {
    staticClass: "form-cancel",
    on: {
      "click": _vm.handleCancel
    }
  }, [_vm._v("取消")]), _c('b-button', {
    staticClass: "form-confirm",
    attrs: {
      "loading": _vm.loading,
      "disabled": _vm.confirmDisabled
    },
    on: {
      "click": _vm.handleLodingConfirm
    }
  }, [_vm._v("确定")])], 1)])]) : _vm._e()]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "message-left"
  }, [_c('p', [_vm._v("单位名称")]), _c('p', [_vm._v("单位类型")]), _c('p', [_vm._v("单位地址")]), _c('p', [_vm._v("账号")]), _c('p', [_vm._v("姓名")]), _c('p', [_vm._v("性别")]), _c('p', [_vm._v("手机号")])]);
}];
export { render, staticRenderFns };