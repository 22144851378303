import "core-js/modules/es.number.constructor.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "order"
  }, [_c('div', {
    staticClass: "order-detail-title"
  }, [_vm._v("我的订单/订单详情")]), _c('div', {
    staticClass: "order-detail-message"
  }, [_c('div', {
    staticClass: "order-detail-information"
  }, [_c('div', {
    staticClass: "information-wrap"
  }, [_c('div', {
    staticClass: "information-title"
  }, [_vm._v("订单信息")]), _c('p', [_vm._v(" 退货单状态: "), _c('span', [_vm._v(_vm._s(_vm._f("statusName")(_vm.data.applyType)))])]), _c('p', [_vm._v(" 退货单编号: "), _c('span', [_vm._v(_vm._s(_vm.data.afterNumber))])]), _c('p', [_vm._v(" 提交时间: "), _c('span', [_vm._v(_vm._s(_vm.data.applyTime))])]), _c('p', [_vm._v(" 关联订单号: "), _c('span', [_vm._v(_vm._s(_vm.data.totalNumber))])]), _c('p', [_vm._v(" 退货原因: "), _c('span', [_vm._v(_vm._s(_vm.data.applyReason))])]), _c('p', [_vm._v(" 备注: "), _c('span', [_vm._v(_vm._s(_vm.data.remark))])])])]), _c('div', {
    staticClass: "order-detail-payment"
  }, [_c('div', {
    staticClass: "payment-wrap"
  }, [_c('div', {
    staticClass: "payment-title"
  }, [_vm._v("退款信息")]), _c('p', [_vm._v(" 退款金额: "), _c('span', [_vm._v("￥" + _vm._s(_vm._f("NumFormat")(Number(_vm.data.totalMoney))))])]), _c('p', [_vm._v(" ERP退货单号: "), _c('span', [_vm._v(_vm._s(_vm.data.erpSaleOrderId))])]), _c('p', [_vm._v(" 退货品规数: "), _c('span', [_vm._v(_vm._s(_vm.data.categoryNumber))])]), _c('p', [_vm._v(" 退货数量: "), _c('span', [_vm._v(_vm._s(_vm.data.totalNumber))])])])])]), _c('div', {
    staticClass: "order-detail-list"
  }, [_vm._m(0), _c('div', {
    staticClass: "product-list"
  }, [_vm._m(1), _c('div', {
    staticClass: "shopp-product-list-box"
  }, _vm._l(_vm.list, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "shopp-product"
    }, [_c('div', {
      staticClass: "product-info"
    }, [_c('div', {
      staticClass: "product-info-main"
    }, [_c('div', {
      staticClass: "product-img-box"
    }, [_c('div', {
      staticClass: "product-img"
    }, [_c('img', {
      attrs: {
        "src": item.productPhoto,
        "alt": ""
      }
    })])]), _c('div', {
      staticClass: "product-introduce"
    }, [_c('p', [_vm._v(_vm._s(item.productName))]), _c('p', [_vm._v("生产厂家:" + _vm._s(item.produceFactory))]), _c('p', [_vm._v(" 效期: " + _vm._s(_vm._f("formatDate")(item.nearEffectivePeriod)) + " ")])])])]), _c('div', {
      staticClass: "product-price"
    }, [_c('p', [_vm._v(_vm._s(item.batchNumber))])]), _c('div', {
      staticClass: "product-num"
    }, [_vm._v(_vm._s(item.totalNumber))]), _c('div', {
      staticClass: "product-subtotal"
    }, [_c('p', [_vm._v(_vm._s(item.sellUnit))])])]);
  }), 0)])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "detail-list-title"
  }, [_c('div', [_vm._v("商品信息")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "product-columns"
  }, [_c('p', {
    staticClass: "columns-product"
  }, [_vm._v("退货商品清单")]), _c('p', {
    staticClass: "columns-price"
  }, [_vm._v("批次号")]), _c('p', {
    staticClass: "columns-num"
  }, [_vm._v("退货数量")]), _c('p', {
    staticClass: "columns-subtotal"
  }, [_vm._v("包装单位")])]);
}];
export { render, staticRenderFns };