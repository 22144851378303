<template>
  <div class="order">
    <div class="order-detail-title">
      <span @click="toBack">我的订单</span>/
      <span>{{ data.orderStatus | statusName }}</span>
    </div>
    <div class="order-detail-message">
      <div class="order-detail-status">
        <div class="status-wrap">
          <div class="status-title">{{ data.orderStatus | statusName }}</div>
          <div class="status-time" v-if="data.orderStatus === '01'">
            <p>倒计时:</p>
            <p v-if="countdownshow">
              <countdown :value="valueTime" format="h时m分s秒" @stop="stopEndTime" />
            </p>
          </div>
          <div
            :class="[
              {
                take:
                  data.orderStatus === '03' ||
                  data.orderStatus === '04' ||
                  data.orderStatus === '02'
              },
              { start: data.orderStatus === '05' },
              'status-button'
            ]"
          >
            <p
              class="once-payment"
              v-if="data.orderStatus === '01'"
              @click="handlePayment(data.orderNumber)"
            >
              立即付款
            </p>
            <p
              class="start-buy"
              v-if="data.orderStatus === '05'"
              @click="anewPurchase(data)"
            >
              重新购买
            </p>
            <p
              class="download"
              v-if="data.orderStatus !== '01'"
              @click="handleDownload"
            >
              下载商品检验报告
            </p>
              <p
                class="download"
                v-if="data.orderStatus === '04'"
                @click="seeInvoice">
                  查看发票
              </p>
            <!-- <p
              class="purchase"
              v-if="data.orderStatus === '04'"
              @click="handleSale(data)"
            >
              售后退货
            </p> -->
          </div>
        </div>
      </div>
      <div class="order-detail-information">
        <div class="information-wrap">
          <div class="information-title">订单信息</div>
          <div class="order-info-wrap">
            <span class="order-info-left">订单编号:</span>
            <span class="order-info-rigth">{{ data.erpSaleOrderId }}</span>
          </div>
          <div class="order-info-wrap">
            <span class="order-info-left">下单时间:</span>
            <span class="order-info-rigth">{{ data.orderAt }}</span>
          </div>
          <div class="order-info-wrap">
            <span class="order-info-left">总品规数:</span>
            <span class="order-info-rigth">{{ data.categoryNumber }}</span>
          </div>
          <div class="order-info-wrap">
            <span class="order-info-left">总数量:</span>
            <span class="order-info-rigth">{{ data.totalNumber }}</span>
          </div>
          <div class="order-info-wrap">
            <span class="order-info-left">发货数量:</span>
            <span class="order-info-rigth">{{ data.deliveryNumber == null ? 0 : data.deliveryNumber }}</span>
          </div>
          <div class="order-info-wrap">
            <span class="order-info-left">配送方式:</span>
            <span class="order-info-rigth">{{ data.distributionWay | distributionWay }}</span>
          </div>
          <div class="order-info-wrap">
            <span class="order-info-left">订单来源:</span>
            <span class="order-info-rigth">{{ data.orderSource | orderSource }}</span>
          </div>
          <div class="order-info-wrap">
            <span class="order-info-left">开具发票:</span>
            <span class="order-info-rigth">{{ data.invoice | invoice }}</span>
          </div>
          <div class="order-info-wrap" v-if="data.companyName">
            <span class="order-info-left">客户名称:</span>
            <span class="order-info-rigth">{{ data.companyName }}</span>
          </div>
        </div>
      </div>
      <div class="order-detail-information">
        <div class="information-wrap">
          <div class="information-title">付款信息</div>
          <div class="order-info-wrap">
            <span class="order-info-left">支付方式:</span>
            <span class="order-info-rigth">{{ data.payWay || data.payType | payWay }}</span>
          </div>
          <div class="order-info-wrap">
            <span class="order-info-left">总金额:</span>
            <span class="order-info-rigth">¥{{ Number(data.totalAmount) | NumFormat }}</span>
          </div>
          <div class="order-info-wrap">
            <span class="order-info-left">满减:</span>
            <span class="order-info-rigth">-¥{{ data.totalReductionAmount == null ? '0.00' : data.totalReductionAmount}}</span>
          </div>
          <div class="order-info-wrap" v-if="data.cashAmount">
            <span class="order-info-left">现金返点:</span>
            <span class="order-info-rigth">-¥{{ data.cashAmount }}</span>
          </div>
          <div class="order-info-wrap" v-if="data.onlinePaymentInstantDiscount">
            <span class="order-info-left" style="width: 114px;">线上支付优惠:</span>
            <span class="order-info-rigth">-¥{{ data.onlinePaymentInstantDiscount }}</span>
          </div>
          <div class="order-info-wrap">
            <span class="order-info-left">凑单返利:</span>
            <span class="order-info-rigth" style="color: #FF0000;font-weight: 400">-¥{{ data.giftAmount }}</span>
            <a class="rebateDetails" v-show="data.orderSource == '2' && data.giftAmount > 0" @click="lookDetails">查看明细</a>
          </div>
          <div class="order-info-wrap">
            <span class="order-info-left">余额抵扣:</span>
            <span class="order-info-rigth">-¥{{ data.balanceAmount }}</span>
          </div>
          <div class="order-info-wrap">
            <span class="order-info-left">优惠券:</span>
            <span class="order-info-rigth">¥{{Number(data.couponPrice) | NumFormat}}</span>
          </div>
          <div class="order-info-wrap">
            <span class="order-info-left">运费:</span>
            <span class="order-info-rigth">¥{{ Number(data.freight) | NumFormat }}</span>
          </div>
          <div class="order-info-wrap">
            <span class="order-info-left">应付金额:</span>
            <span class="order-info-rigth" v-if="data.realPaidAmount != null">¥{{ Number(data.realPaidAmount) | NumFormat }}</span>
          </div>
          <div class="order-info-wrap">
            <span class="order-info-left">实付金额:</span>
            <span
              class="order-info-rigth"
              v-if="data.realPayAmount != null && (data.payWay == 'ALIPAY' || data.payWay == 'WXPAY')">
              ¥{{ Number(data.realPayAmount) | NumFormat }}
            </span>
            <span class="order-info-rigth" v-else-if="data.payWay != 'ALIPAY' && data.payWay != 'WXPAY'">未结算或其他方式支付</span>
          </div>
          <div class="order-info-wrap" v-if="data.payType === 'ONLINE'">
            <span class="order-info-left">支付状态:</span>
            <span class="order-info-rigth">{{ payStatus[data.payStatus || "WAIT"] }}</span>
          </div>
          <div class="order-info-wrap" v-if="data.payType === 'ONLINE'">
            <span class="order-info-left">支付号:</span>
            <span class="order-info-rigth" v-if="data.realPaidAmount != null">{{ data.cpcnTradeNo == null ? '--' : data.cpcnTradeNo }}</span>
          </div>
        </div>
      </div>
      <div class="order-detail-information">
        <div class="information-wrap">
          <div class="information-title">收货人信息</div>
          <div class="order-info-wrap">
            <span class="order-info-left">收货人:</span>
            <span class="order-info-rigth">{{ data.userName }}</span>
          </div>
          <div class="order-info-wrap">
            <span class="order-info-left">地址:</span>
            <span class="order-info-rigth">{{ data.distributionAddr }}</span>
          </div>
          <div class="order-info-wrap">
            <span class="order-info-left">联系方式:</span>
            <span class="order-info-rigth">{{ data.phoneNumber }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="order-detail-list">
      <div class="detail-list-title">
        <div>商品信息</div>
        <!-- <div @click="handleDownload">下载药品报告书</div> -->
      </div>
      <div class="product-list">
        <div class="product-columns">
          <p class="columns-product">商品</p>
          <p class="columns-price">单价</p>
          <p class="columns-num">订单数量</p>
          <p class="columns-subtotal">小计</p>
          <p class="product-practical" v-if="data.orderStatus == '03' || data.orderStatus == '04'">发货数量</p>
          <!--2021-11-11 hy修改-->
<!--          <p class="product-number">库存批次号</p>-->
<!--          <p class="product-yield">生产批号</p>-->
          <p class="columns-handle">操作</p>
        </div>
        <!-- 商品info -->
        <div class="shopp-product-list-box">
          <div class="shopp-product" v-for="(item, index) in list" :key="index">
            <div class="product-info">
              <div class="product-info-main">
                <!-- 商品图片 -->
                <div class="product-img-box">
                  <div class="product-img">
                    <img :src="item.productPhoto" alt />
                  </div>
                  <!--零差价标识-->
                  <div class="zero-price-img" v-if="item.isZeroPriceDifference == 1">
                    <img src="@images/PC0.png">
                  </div>
                  <div class="product-hbimg-jf" v-if="item.productAttribute=='1'">
                    返利
                  </div>
                  <div class="hospital" v-if="item.hospitalClassificationValue && item.hospitalClassificationValue == '1'">
                    医院
                  </div>
                  <img v-if="item.productPatent && item.productPatent == '1'" class="db_icon" src="https://obs.pujian.com/frontend/app/components/db_icon.png" alt />
                  <!-- 库存数量tag -->
                  <!-- <div class="state-tag low-stock">仅剩{{ item.stock }}</div> -->
                  <!-- <div class="state-tag invalid">商品已失效</div> -->
                  <!-- <div class="state-tag out-of-stock">无货</div> -->
                </div>
                <!-- 商品描述 -->
                <div class="product-introduce">
                  <p>【{{ item.specifications }}】{{ item.productName }}</p>
                  <p>{{ item.produceFactory }}</p>
                  <p v-if="item.nearEffectivePeriod">效期 {{ item.nearEffectivePeriod | formatDate }}</p>
                  <p v-else>效期</p>
                  <p class="lastTime">最后退货时间:{{item.lastReturnTime}}</p>
                </div>
              </div>
              <!-- <div class="special-offer">
                <p>活动</p>
                <p>满5袋减5元；满10袋减12元</p>
              </div> -->
            </div>

            <!-- 商品价格 -->
            <div class="product-price">
                <div v-if="item.superDiscountProductPrice">
                    <p class="SpecialPrice">
                        <span>特价</span><span>￥{{ item.superDiscountProductPrice }}</span><span v-if="item.sellUnit">/{{ item.sellUnit }}</span>
                    </p>
                    <p class="tj-oldPrice" v-if="item.superDiscountProductPrice">
                        {{ Number(item.unitPrice) | NumFormat }}
                        <span v-if="item.sellUnit">/{{ item.sellUnit }}</span>
                    </p>
                </div>
              <p v-else>
                ¥{{ Number(item.unitPrice) | NumFormat }}
                <span v-if="item.sellUnit">/{{ item.sellUnit }}</span>
              </p>
              <!-- <s>¥{{ item.preferentialPrice | NumFormat }}</s> -->
            </div>
            <!-- 商品数量 -->
            <div class="product-num">
              <p>
                {{ item.totalNumber }} <span v-if="item.tipsStr">中包装</span>
              </p>
              <span class="num" v-if="item.tipsStr">(实际购买{{ item.tipsStr | unit }}{{ item.sellUnit }})</span>
            </div>
            <!-- 小计金额 -->
            <div class="product-subtotal">
              <p class="subtotal-first">¥{{ item.subtotalMoney }}</p>
              <p
                class="subtotal-last"
                v-if="item.preferentialTotalMoney != null"
              >
                优惠¥{{ item.preferentialTotalMoney }}
              </p>
            </div>
            <!--发货数量-->
            <div class="md-product-practical" v-if="data.orderStatus == '03' || data.orderStatus == '04'">
              {{ item.deliveryNumber }}
            </div>
            <!--2021-11-11 hy修改-->
<!--            <div class="md-product-number">{{ item.batchNumber }}</div>-->
<!--            <div class="md-product-yield">{{ item.productionBatchNumber }}</div>-->
            <div class="product-handle">
              <p @click="addCart(item)">加入购物车</p>
              <!--2021-11-11 hy修改-->
              <b-tooltip
                position="is-bottom"
                multilined
                type="is-light"
                :triggers="['click']"
                :auto-close="['outside', 'escape']"
              >
                <div class="lookDetails">
                  <p>查看详情</p>
                  <div class="corner"></div>
                </div>
                <template v-slot:content>
                  <div class="tip-item">
                    <b style="width: 50%">生产批号</b>
                    <b style="width: 50%">数量</b>
                  </div>
                  <div class="tip-item" v-for="(list,listIndex) in item.orderLineBatchVos" :key="listIndex">
<!--                    <b style="width: 40%">{{list.erpOrderId}}</b>-->
                    <b style="width: 50%">{{list.productionBatchNumber}}</b>
                    <b style="width: 50%">{{list.batchDeliveryNumber}}</b>
                  </div>
                  <div v-if="item.orderLineBatchVos.length == 0" style="margin: 10px 0">暂无数据</div>
                </template>
              </b-tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="loding" v-if="popupStatus">
      <div class="loding-wrap">
        <div class="loding-title">申请开票</div>
        <div class="form">
          <b-field
            horizontal
            label="发票抬头"
            :type="danger ? 'is-danger' : ''"
            :message="dangerMessage ? '不能为空' : ''"
          >
            <b-input
              name="subject"
              @blur="blurDanger"
              v-model="info.rise"
              placeholder="请输入"
              expanded
              icon-right="close-circle"
              icon-right-clickable
              @icon-right-click="clearIconClick"
            >
            </b-input>
            <div class="form-management" @click="handleManagement">
              管理发票抬头
            </div>
          </b-field>
          <b-field
            horizontal
            label="纳税人识别号"
            :type="duty ? 'is-danger' : ''"
            :message="dutyMessage ? '请输入正确税号' : ''"
          >
            <b-input
              name="subject"
              expanded
              v-model="info.duty"
              @blur="blurDuty"
              placeholder="请输入"
            >
            </b-input>
          </b-field>
          <b-field horizontal label="备注" class="textareas">
            <b-input type="textarea" v-model="info.remark"></b-input>
          </b-field>
          <div class="mode">
            <p class="mode-title">开票方式</p>
            <b-radio
              v-model="radio"
              name="电子发票"
              type="is-info"
              v-for="item in radioList"
              :key="item.id"
              :native-value="item.value"
            >
              {{ item.name }}
            </b-radio>
          </div>
          <div class="buttons">
            <span class="form-cancel" @click="handleCancel">取消</span>
            <span class="form-confirm" @click="handleConfirm">确定</span>
          </div>
        </div>
      </div>
    </div>

    <div class="download-loding" v-if="isDownload">
      <div class="download-loding-wrap">
        <div class="download-loding-title">下载商品检验报告</div>
        <div class="form">
          <div class="means-list">
            <div class="means-title">
              <p></p>
              <p class="ptitle">商品名称</p>
              <p class="ptitle">规格</p>
              <p class="ptitle">生产厂家</p>
              <p class="ptitle">生产批号</p>
              <p></p>
            </div>
            <ul>
              <li
                v-for="(item, index) in formList"
                :key="index"
                class="means-item clearfix"
              >
                <b-checkbox
                  type="is-info"
                  v-model="item.isCheckedName"
                  @input="getSelectItem($event, item)"
                ></b-checkbox>
                <span class="name">{{ item.productName }}</span>
                <span class="name">{{ item.specifications }}</span>
                <span class="name">{{ item.produceFactory }}</span>
                <span class="name">{{ item.batchNumber || "--" }}</span>
                <span class="downFlag">{{item.isDownload ? "已下载" : "未下载" }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="all">
          <p>
            <b-checkbox
              size="is-small"
              type="is-info"
              v-model="selectAll"
              @input="getSelectAll"
              >全选</b-checkbox>
          </p>
          <span class="text">共{{ arr.length }}个文件，总计{{ checkedAllSize }}M</span>
        </div>
        <div class="buttons">
          <span class="form-cancel" @click="handleCancelDownload">取消</span>
          <span
            v-if="hasChecked"
            class="form-confirm"
            @click="handleConfirmDownload"
            >确定</span>
          <span v-else class="form-confirm-den">确定</span>
        </div>
      </div>
    </div>
    <!-- <b-modal
      v-model="isComponentModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
    >
      <template #default>
        <div class="dialog-inner">
          <div class="title">
            <span class="text">消息提示</span>
            <span class="close" @click="isComponentModalActive = false"
              >&#10005;</span
            >
          </div>
          <div class="content">
            <p>选中的资料文件超过20M，无法进行下载操作</p>
          </div>
        </div>
      </template>
    </b-modal> -->
  </div>
</template>

<script>
const dutyReg = /^[A-Za-z0-9]+$/;
import { AccountStorage } from "@services/account";
import { Countdown } from "@components/countdown";
const accountStorage = new AccountStorage();

const PayStatus = {
  WAIT: "待支付",
  PAYING: "支付中",
  SUCCESS: "支付成功",
  FAIL: "支付失败",
  CLOSED: "已取消",
  REFUNDED: "已退款"
};

export default {
  components: {
    Countdown
  },
  data() {
    return {
      payStatus: PayStatus,
      countdownshow: false,
      valueTime: "",
      data: {},
      list: [],
      info: {
        rise: null,
        duty: "",
        remark: ""
      },
      radio: "",
      radioList: [
        {
          id: 1,
          value: 1,
          name: "电子发票"
        },
        {
          id: 2,
          value: 2,
          name: "纸质发票"
        }
      ],
      popupStatus: false,
      isDownload: false,
      danger: false,
      dangerMessage: false,
      duty: false, // 税号校验
      dutyMessage: false,
      formList: [],
      checkedRows: [],
      productIds: [],
      userId: "",
      ids: [],
      selectAll: false,
      arr: [] // 选中的文件数
      // isComponentModalActive: false
    };
  },
  filters: {
    statusName(type) {
      switch (type) {
        case "01":
          return "待支付";
        case "02":
          return "待发货";
        case "03":
          return "待收货";
        case "04":
          return "交易完成";
        case "05":
          return "已取消";
      }
    },
    unit(type) {
      let a = type.indexOf("*");
      let num = type.substring(1, a) * type.substring(a + 1, type.length - 1);
      return num;
    },
    orderSource(type) {
      switch (type) {
        case "1":
          return "商城下单";
        case "2":
          return "erp同步";
      }
    },
    distributionWay(type) {
      switch (type) {
        case "1":
          return "自有配送";
        case "2":
          return "京东配送";
        case "3":
          return "其他托运";
        case "4":
          return "仓库自提";
        case "5":
          return "门市自提";
      }
    },
    payWay(type) {
      let statusObj = {
        ONLINE: "线上支付",
        ALIPAY: "支付宝支付",
        WXPAY: "微信支付",
        COD: "货到付款"
      };
      return statusObj[type];
    },
    invoice(type) {
      let statusObj = {
        1: "是",
        2: "否"
      };
      return statusObj[type];
    }
  },
  created() {
    let newUserId = accountStorage.getCacheUserInfo();
    this.userId = newUserId.userId;
    this.getOrderDetail();
  },
  computed: {
    checkedAllSize() {
      let val = 0;
      if (this.arr.length) {
        this.formList.forEach(item => {
          this.arr.forEach(v => {
            if (item.pictureUrl === v.pictureUrl) {
              val += Number(item.pictureSize);
            }
          });
        });
      }
      return val.toFixed(1);
    },
    hasChecked() {
      return this.formList.some(item => {
        return item.isCheckedName === true;
      });
    }
  },
  methods: {
      //查看发票
      seeInvoice(){
          this.$router.push({
              name: "electronicInvoice",
              query: {
                  orderNumber: this.$route.params.orderNumber
              }
          });
      },
    //查看返利详情
    lookDetails() {
      let orderInfo = {
        orderNumber: this.$route.params.orderNumber,
        giftAmount: this.data.giftAmount
      };
      orderInfo = JSON.stringify(orderInfo);
      this.$router.push({
        name: "orderDetailRebate",
        query: {
          orderInfo: orderInfo
        }
      });
    },
    // 点击头部订单
    toBack() {
      this.$router.push({
        name: "order",
        params: {
          status: "0"
        }
      });
    },
    stopEndTime() {
      this.getOrderDetail();
    },
    clearIconClick() {
      this.info.rise = null;
    },
    // 订单详情
    getOrderDetail() {
      this.list = [];
      let id = this.$route.params.orderNumber;
      let url = `/pjyy-deepexi-order-center/api/v1/order/OcOrderLine/orderLineList?orderNumber=${id}`;
      this.$getHttpClient()
        .get(url)
        .then(res => {
          this.data = res.data.data;
          let arr = [];
          arr = res.data.data.orderLines;
          arr.forEach(item => {
            // if (item.orderLineBatchVos.length != 0) {
              // item.orderLineBatchVos.forEach(it => {
                // this.list.push({
                  // ...item,
                  // batchNumber: it.batchNumber,
                  // productionBatchNumber: it.productionBatchNumber,
                  // deliveryNumber: it.batchDeliveryNumber,
                  // totalNumber: it.totalNumber
              //   });
              // });
            // } else {
              this.list.push(item);
            // }
          });
          this.orderId = res.data.data.id;
          this.valueTime = Math.ceil(this.data.closeTime / 1000);
          this.countdownshow = true;
        });
    },
    // 重新购买
    anewPurchase(item) {
      var arr = [];
      item.orderLines.forEach(ele => {
        arr.push(ele.productId);
      });
      let url = `/pjyy-deepexi-order-center/api/v1/cart/againAddGoodsToCartList`;
      let data = {
        orderNumber: item.orderNumber
      };
      this.$getHttpClient()
        .post(url, data)
        .then(res => {
          if (res.data.code === "200") {
            this.$buefy.toast.open({
              message: "操作成功",
              type: "is-success"
            });
            this.getOrderDetail();
            this.$router.push({
              path: "/cart/cartList"
            });
          }
        });
    },
    // 再次购买
    againPurchase(item) {
      var arr = [];
      item.orderLines.forEach(ele => {
        arr.push(ele.productId);
      });
      let url = `/pjyy-deepexi-order-center/api/v1/cart/againAddGoodsToCartList`;
      let data = {
        orderNumber: item.orderNumber
      };
      this.$getHttpClient()
        .post(url, data)
        .then(res => {
          if (res.data.code === "200") {
            this.$buefy.toast.open({
              message: "操作成功",
              type: "is-success"
            });
            this.getOrderDetail();
            this.$router.push({
              path: "/cart/cartList"
            });
          }
        });
    },
    // 下载报告
    handleDownload() {
      document.body.style.height = `${document.documentElement.clientHeight}px`;
      document.body.style.overflow = "hidden";
      this.isDownload = true;
      this.list.forEach(item => {
        this.productIds.push(item.productId);
      });
      let productIds = this.productIds;
      let data = {
        productIds,
        orderid: this.data.id.toString()
      };
      let url = `/pjyy-deepexi-product-center/api/v1/product/drug/findAll`;
      this.$getHttpClient()
        .post(url, data)
        .then(res => {
          if (res.data.code === "200") {
            this.formList = [];
            let arr = res.data.data;
            arr.forEach(item => {
              this.formList.push({
                isCheckedName: false,
                ...item
              });
            });
          }
        });
    },
    // 下载报告全选确定
    handleConfirmDownload() {
      document.body.style.height = "";
      document.body.style.overflow = "";
      this.productIds = [];
      this.isDownload = false;
      this.ids = [];
      let batchNumbers = [];
      this.formList.forEach(item => {
        if (item.isCheckedName) {
          this.ids.push(item.productId);
          batchNumbers.push(item.batchNumber);
        }
      });
      // process.env.VUE_APP_WEB_URL  获取当前请求的env文件配置
      let url = `${process.env.VUE_APP_WEB_URL}/pjyy-deepexi-product-center/api/v1/product/drug/downloadDrugPc`;
      url + `?orderId=${this.data.orderNumber}&productIds=${this.ids}&batchNumber=${batchNumbers}&userId=${this.getUserId()}`
      location.href = url;
    },
    // 全选
    getSelectAll(val) {
      this.selectAll = val;
      this.arr = [];
      this.formList.forEach(item => {
        item.isCheckedName = val;
        if (item.isCheckedName) {
          this.arr.push(item);
        }
      });
    },
    // 单个勾选
    getSelectItem() {
      let arr = [];
      this.formList.forEach(item => {
        if (item.isCheckedName) {
          arr.push(item);
        }
      });
      this.arr = arr;
      this.selectAll = this.formList.every(item => item.isCheckedName === true);
    },
    // 下载取消按钮
    handleCancelDownload() {
      document.body.style.height = "";
      document.body.style.overflow = "";
      this.isDownload = false;
      this.ids = [];
      this.productIds = [];
    },
    // 立即付款
    handlePayment(orderNumber) {
      this.$router.push({
        name: "payMent",
        params: {
          orderId: orderNumber
        }
      });
    },
    //获取用户ID
    getUserId() {
      let userInfo = accountStorage.getCacheUserInfo();
      if (userInfo) {
        return userInfo.userId;
      }
      return;
    },
    // 加入购物车
    addCart(item) {
      const URL = "/pjyy-deepexi-order-center/api/v1/cart/addGoodsToCartList";
      const data = {
        productId: Number(item.productId),
        userId: Number(this.getUserId()),
        totalNumber: item.totalNumber
      };
      this.$getHttpClient()
        .post(URL, data)
        .then(res => {
          if (res.data.code === "200") {
            this.$buefy.toast.open({
              message: "加入成功",
              type: "is-success"
            });
            this.$router.push({
              path: "/cart/cartList"
            });
          }
        });
    },
    blurDanger() {
      if (this.info.rise === null) {
        this.danger = true;
        this.dangerMessage = true;
      } else {
        this.danger = false;
        this.dangerMessage = false;
      }
    },
    // 税号
    blurDuty() {
      if (dutyReg.test(this.info.duty) && this.info.duty !== "") {
        this.duty = false;
        this.dutyMessage = false;
      } else {
        this.duty = true;
        this.dutyMessage = true;
      }
    },
    handleLoding() {
      document.body.style.height = `${document.documentElement.clientHeight}px`;
      document.body.style.overflow = "hidden";
      this.popupStatus = true;
    },
    handleCancel() {
      document.body.style.height = "";
      document.body.style.overflow = "";
      this.popupStatus = false;
    },
    // 申请开票的确定按钮
    handleConfirm() {
      if (this.info.rise === null) {
        this.danger = true;
        this.dangerMessage = true;
        return;
      }
      if (dutyReg.test(this.info.duty) && this.info.duty !== "") {
        this.duty = false;
        this.dutyMessage = false;
      } else {
        this.duty = true;
        this.dutyMessage = true;
        return;
      }
      let url = `/pjyy-deepexi-order-center/api/v1/order/OcOrder/OrderInvoice`;
      let data = {
        id: this.$route.params.id,
        invoiceName: this.info.rise,
        invoiceIdentify: this.info.duty,
        invoiceRemark: this.info.remark,
        invoiceType: String(this.radio)
      };
      this.$getHttpClient()
        .post(url, data)
        .then(res => {
          if (res.data.code === "200") {
            this.$buefy.toast.open({
              message: "申请开票成功",
              type: "is-success"
            });
          }
        });
      document.body.style.height = "";
      document.body.style.overflow = "";
      this.popupStatus = false;
    },
    // 取消订单
    updateOrderStatus(orderNumber) {
      let url = `/pjyy-deepexi-order-center/api/v1/order/OcOrder/updateOrderStatus05?orderNumber=${orderNumber}`;
      this.$getHttpClient()
        .get(url)
        .then(res => {
          if (res.data.code === "200") {
            this.getOrderDetail();
          }
        });
    },
    // 跳转发票抬头
    handleManagement() {
      this.$router.push({
        path: `invoice`
      });
    },
    // 跳转申请退货
    handleSales() {
      // let id = 1;
      // this.$router.push({
      //   path: `apply-goods?id=${id}`
      // });
    },
    // 售后退货
    handleSale(data) {
      this.$router.push({
        name: "salesDetail",
        params: {
          status: "1",
          orderNumber: data.orderNumber
        }
      });
    }
  },
  updated() {}
};
</script>
<style src="./order-detail.less" lang="less" scoped></style>
