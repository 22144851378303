export var genderList = [{
  id: 1,
  value: "正常退货"
}, {
  id: 2,
  value: "滞销"
}, {
  id: 3,
  value: "业务原因"
}, {
  id: 4,
  value: "无外包装"
}, {
  id: 5,
  value: "过效期"
}, {
  id: 6,
  value: "价格错误"
}, {
  id: 7,
  value: "有效期错误"
}, {
  id: 8,
  value: "单位入错"
}, {
  id: 9,
  value: "近效期"
}, {
  id: 10,
  value: "破损"
}, {
  id: 11,
  value: "药监局禁用"
}, {
  id: 12,
  value: "即将过期"
}, {
  id: 13,
  value: "单位开错"
}, {
  id: 14,
  value: "地址开错"
}, {
  id: 15,
  value: "规格错误"
}, {
  id: 16,
  value: "信息录错"
}, {
  id: 17,
  value: "未出库"
}, {
  id: 18,
  value: "供应商入错"
}, {
  id: 19,
  value: "数量开错"
}, {
  id: 20,
  value: "价格开错"
}, {
  id: 21,
  value: "召回退货"
}, {
  id: 22,
  value: "不合格处理"
}, {
  id: 23,
  value: "数量入错"
}];