import { render, staticRenderFns } from "./order-detail-rebate.vue?vue&type=template&id=ca7dda56&scoped=true"
import script from "./order-detail-rebate.vue?vue&type=script&lang=js"
export * from "./order-detail-rebate.vue?vue&type=script&lang=js"
import style0 from "../order-detail-rebate/order-detail-rebate.less?vue&type=style&index=0&id=ca7dda56&prod&lang=less&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca7dda56",
  null
  
)

export default component.exports