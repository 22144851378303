import "core-js/modules/es.number.constructor.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "debt-message-detail"
  }, [_c('div', {
    staticClass: "top"
  }, [_c('div', {
    staticClass: "title-decoration",
    on: {
      "click": _vm.handleBack
    }
  }, [_vm._v("返回上一级")])]), _vm.list.length != 0 ? _c('div', {
    staticClass: "top-message"
  }, [_c('span', [_vm._v(_vm._s(_vm._f("formatDate1")(_vm.totalAmountOwe.execDate)) + " 欠款总金额：¥ " + _vm._s(_vm._f("NumFormat")(Number(_vm.totalAmountOwe.totalAmountOwe))))])]) : _vm._e(), _c('div', {
    staticClass: "content clearfix"
  }, [_vm.list.length != 0 ? _c('ol', {
    staticClass: "product-title-list"
  }, [_c('li', [_vm._v("生效日期")]), _c('li', [_vm._v("欠款金额(¥)")]), _c('li', [_vm._v("欠款条张数")]), _c('li', [_vm._v("欠款单备注")]), _c('li', [_vm._v("提货方式")]), _c('li', [_vm._v("锁抬头提醒")])]) : _vm._e(), _c('ul', {
    staticClass: "product-list"
  }, _vm._l(_vm.list, function (v, i) {
    return _c('li', {
      key: i,
      staticClass: "product-message-detail clearfix"
    }, [_c('div', {
      staticClass: "debt-time"
    }, [_c('span', {
      staticClass: "time"
    }, [_vm._v(_vm._s(v.execDate))])]), _c('div', {
      staticClass: "debt-money"
    }, [_vm._v(_vm._s(_vm._f("NumFormat")(v.amountOwe)))]), _c('div', {
      staticClass: "debt-number"
    }, [_vm._v(_vm._s(v.iouCount))]), _c('div', {
      staticClass: "debt-remark"
    }, [_vm._v(_vm._s(v.iouRemarks))]), _c('div', {
      staticClass: "debt-mode"
    }, [_vm._v(_vm._s(v.distributionWay))]), _c('div', {
      staticClass: "debt-remind"
    }, [_vm._v(_vm._s(v.lockHeadRemind))])]);
  }), 0), _vm.list.length !== 0 ? _c('div', {
    staticClass: "pagination-box"
  }, [_c('b-pagination', {
    staticClass: "is-centered",
    attrs: {
      "total": _vm.total,
      "current": _vm.current,
      "per-page": _vm.perPage,
      "range-before": _vm.rangeBefore,
      "range-after": _vm.rangeAfter
    },
    on: {
      "update:current": function updateCurrent($event) {
        _vm.current = $event;
      },
      "change": _vm.handleChange
    }
  })], 1) : _vm._e()]), _vm.list.length == 0 ? [_vm._m(0)] : _vm._e()], 2);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "no-result-wrap"
  }, [_c('img', {
    attrs: {
      "src": require("./images/no-result.png"),
      "alt": ""
    }
  })]);
}];
export { render, staticRenderFns };