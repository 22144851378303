import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.number.to-fixed.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "data-download-detail"
  }, [_c('div', {
    staticClass: "top"
  }, [_c('div', {
    staticClass: "title-decoration",
    on: {
      "click": _vm.handleBack
    }
  }, [_vm._v("返回上一级")])]), _c('div', {
    staticClass: "content clearfix"
  }, [_c('ul', {
    staticClass: "product-list"
  }, [_c('li', {
    staticClass: "product-item clearfix"
  }, [_c('div', {
    staticClass: "product"
  }, [_c('img', {
    attrs: {
      "src": _vm.detailData.mainImageUrl,
      "alt": "",
      "title": ""
    }
  }), _vm.detailData.prescription + '' === '8906' ? _c('span', {
    staticClass: "tag OTC-green"
  }, [_vm._v("OTC")]) : _vm._e(), _vm.detailData.prescription + '' === '8905' ? _c('span', {
    staticClass: "tag OTC-red"
  }, [_vm._v("OTC")]) : _vm._e(), _vm.detailData.prescription + '' === '8904' ? _c('span', {
    staticClass: "tag RX-red"
  }, [_vm._v("RX")]) : _vm._e(), _c('span', {
    staticClass: "name"
  }, [_vm._v("【" + _vm._s(_vm.detailData.specifications) + "】" + _vm._s(_vm.detailData.productName))])]), _c('div', {
    staticClass: "company"
  }, [_vm._v(_vm._s(_vm.detailData.produceFactory))]), _c('div', {
    staticClass: "medicine"
  }, [_vm._v(_vm._s(_vm.detailData.approvalNumber))])])]), _c('div', {
    staticClass: "means-list"
  }, [_c('ul', _vm._l(_vm.list, function (item, index) {
    return _c('li', {
      key: index,
      staticClass: "means-item clearfix"
    }, [_c('label', {
      staticClass: "clearfix"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.checked,
        expression: "checked"
      }],
      attrs: {
        "type": "checkbox"
      },
      domProps: {
        "value": item.pictureUrl,
        "checked": Array.isArray(_vm.checked) ? _vm._i(_vm.checked, item.pictureUrl) > -1 : _vm.checked
      },
      on: {
        "change": function change($event) {
          var $$a = _vm.checked,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = item.pictureUrl,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && (_vm.checked = $$a.concat([$$v]));
            } else {
              $$i > -1 && (_vm.checked = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.checked = $$c;
          }
        }
      }
    }), _c('span', {
      staticClass: "name"
    }, [_vm._v(_vm._s(item.pictureName))]), _c('span', {
      staticClass: "size"
    }, [_vm._v(_vm._s((item.pictureSize / 1024).toFixed(1)) + "M")])]), _c('div', {
      staticClass: "btn"
    }, [_c('span', {
      on: {
        "click": function click($event) {
          return _vm.onDownload(item);
        }
      }
    }, [_vm._v("下载")])])]);
  }), 0), _c('div', {
    staticClass: "all clearfix"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.checkedAll,
      expression: "checkedAll"
    }],
    attrs: {
      "type": "checkbox",
      "id": "all"
    },
    domProps: {
      "checked": Array.isArray(_vm.checkedAll) ? _vm._i(_vm.checkedAll, null) > -1 : _vm.checkedAll
    },
    on: {
      "click": function click($event) {
        return _vm.selectAll();
      },
      "change": function change($event) {
        var $$a = _vm.checkedAll,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.checkedAll = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.checkedAll = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.checkedAll = $$c;
        }
      }
    }
  }), _c('label', {
    attrs: {
      "for": "all"
    }
  }, [_vm._v("全选")]), _c('span', {
    staticClass: "text"
  }, [_vm._v("共" + _vm._s(_vm.checked.length) + "个文件，总计" + _vm._s(_vm.checkedAllSize) + "M")])])]), _vm.list.length !== 0 ? _c('div', {
    staticClass: "pagination-box"
  }, [_c('b-pagination', {
    staticClass: "is-centered",
    attrs: {
      "total": _vm.total,
      "current": _vm.current,
      "per-page": _vm.perPage,
      "range-before": _vm.rangeBefore,
      "range-after": _vm.rangeAfter
    },
    on: {
      "update:current": function updateCurrent($event) {
        _vm.current = $event;
      },
      "change": _vm.handleChange
    }
  })], 1) : _vm._e(), _c('b-modal', {
    attrs: {
      "has-modal-card": "",
      "trap-focus": "",
      "destroy-on-hide": false,
      "aria-role": "dialog",
      "aria-modal": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_c('div', {
          staticClass: "dialog-inner"
        }, [_c('div', {
          staticClass: "title"
        }, [_c('span', {
          staticClass: "text"
        }, [_vm._v("消息提示")]), _c('span', {
          staticClass: "close",
          on: {
            "click": function click($event) {
              _vm.isComponentModalActive = false;
            }
          }
        }, [_vm._v("✕")])]), _c('div', {
          staticClass: "content"
        }, [_c('p', [_vm._v("选中的资料文件超过20M，无法进行下载操作")])])])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.isComponentModalActive,
      callback: function callback($$v) {
        _vm.isComponentModalActive = $$v;
      },
      expression: "isComponentModalActive"
    }
  })], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };