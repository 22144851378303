import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
export default {
  components: {},
  data: function data() {
    return {
      selectAll: false,
      //是否全选
      numberAll: false,
      // 批号是否全选
      radio: "",
      // 退货原因单选
      num: 1,
      // 数量
      // 购物车数据
      shoppList: [{
        productUrl: "https://ss2.bdstatic.com/70cFvnSh_Q1YnxGkpoWK1HF6hhy/it/u=2846453121,3989575347&fm=15&gp=0.jpg",
        productName: "【300s*180片】Swisse进口葡萄籽高浓度原花青素维生素阿哈迪斯",
        productCompany: "北京麦迪海药业有限责任公司",
        effectiveTime: "近/远效期2022.08.28/2022.08.29",
        price: "129.00",
        oldPrice: "399.00",
        count: 1,
        discount: 100,
        //优惠价格
        stock: 12,
        //库存
        invalid: 0,
        //是否失效（1失效，0没有失效）
        inStock: 1,
        //是否有货（1有，0没有）
        checked: true,
        batch: [{
          batchNum: "全部",
          checked1: false
        }, {
          batchNum: "202202280001",
          checked1: false
        }, {
          batchNum: "202202280001",
          checked1: false
        }, {
          batchNum: "202202280001",
          checked1: false
        }, {
          batchNum: "202202280001",
          checked1: false
        }, {
          batchNum: "202202280001",
          checked1: false
        }, {
          batchNum: "202202280001",
          checked1: false
        }, {
          batchNum: "202202280001",
          checked1: false
        }],
        radio: [{
          id: "1",
          radioName: "质量问题"
        }, {
          id: "2",
          radioName: "配件问题"
        }, {
          id: "3",
          radioName: "少发/漏发"
        }, {
          id: "4",
          radioName: "与商品描述不符"
        }, {
          id: "5",
          radioName: "包装/商品残缺"
        }, {
          id: "6",
          radioName: "发票问题"
        }, {
          id: "7",
          radioName: "其他"
        }]
      }, {
        productUrl: "https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1595488511674&di=a892e803898342338039d4d19e27d8f2&imgtype=0&src=http%3A%2F%2Fpic1.win4000.com%2Fwallpaper%2F2018-08-31%2F5b89223acb8e0.jpg",
        productName: "【300s*180片】Swisse进口葡萄籽高浓度原花青素维生素阿哈迪斯",
        productCompany: "北京麦迪海药业有限责任公司",
        effectiveTime: "近/远效期2022.08.28/2022.08.29",
        price: "129.00",
        oldPrice: "399.00",
        count: 1,
        discount: 100,
        //优惠价格
        stock: 12,
        //库存
        invalid: 0,
        //是否失效（1失效，0没有失效）
        inStock: 1,
        //是否有货（1有，0没有）
        checked: false,
        batch: [{
          batchNum: "202202280001"
        }, {
          batchNum: "202202280001"
        }, {
          batchNum: "202202280001"
        }, {
          batchNum: "202202280001"
        }, {
          batchNum: "202202280001"
        }]
      }, {
        productUrl: "https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1595488511674&di=a892e803898342338039d4d19e27d8f2&imgtype=0&src=http%3A%2F%2Fpic1.win4000.com%2Fwallpaper%2F2018-08-31%2F5b89223acb8e0.jpg",
        productName: "【300s*180片】Swisse进口葡萄籽高浓度原花青素维生素阿哈迪斯",
        productCompany: "北京麦迪海药业有限责任公司",
        effectiveTime: "近/远效期2022.08.28/2022.08.29",
        price: "129.00",
        oldPrice: "399.00",
        count: 1,
        discount: 100,
        //优惠价格
        stock: 12,
        //库存
        invalid: 0,
        //是否失效（1失效，0没有失效）
        inStock: 1 //是否有货（1有，0没有）
      }, {
        productUrl: "https://ss2.bdstatic.com/70cFvnSh_Q1YnxGkpoWK1HF6hhy/it/u=2846453121,3989575347&fm=15&gp=0.jpg",
        productName: "【300s*180片】Swisse进口葡萄籽高浓度原花青素维生素阿哈迪斯",
        productCompany: "北京麦迪海药业有限责任公司",
        effectiveTime: "近/远效期2022.08.28/2022.08.29",
        price: "129.00",
        oldPrice: "399.00",
        count: 1,
        discount: 100,
        //优惠价格
        stock: 12,
        //库存
        invalid: 0,
        //是否失效（1失效，0没有失效）
        inStock: 1,
        //是否有货（1有，0没有）
        checked: true
      }]
    };
  },
  methods: {
    // 单个勾选
    getSelectItem: function getSelectItem() {},
    // 全选
    getSelectAll: function getSelectAll(val) {
      this.selectAll = val;
      this.shoppList.forEach(function (item) {
        item.checked = val;
      });
    },
    // 批号是否全选
    getNumberAll: function getNumberAll(val) {
      this.numberAll = val;
    },
    // 批号单选
    handleChildrenChecked: function handleChildrenChecked() {},
    // 单选
    handleRadio: function handleRadio() {},
    // 增加
    handleAdd: function handleAdd() {
      this.num++;
    },
    // 减少
    handleReduce: function handleReduce() {
      if (this.num <= 1) {
        return false;
      }
      this.num--;
    },
    // 确认退货
    toSubmit: function toSubmit() {
      // this.$router.push({
      //   path: "/cart/orderConfirm"
      // });
    }
  },
  updated: function updated() {},
  mounted: function mounted() {}
};