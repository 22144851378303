import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "order"
  }, [_c('div', {
    staticClass: "order-detail-title"
  }, [_c('span', {
    on: {
      "click": _vm.toBack
    }
  }, [_vm._v("我的订单")]), _vm._v("/ "), _c('span', [_vm._v(_vm._s(_vm._f("statusName")(_vm.data.orderStatus)) + "/返利明细")])]), _c('div', {
    staticClass: "rebate-detail-message"
  }, [_c('div', {
    staticClass: "rebate-title"
  }, [_c('span', {
    staticClass: "title_num"
  }, [_vm._v("共 " + _vm._s(_vm.list.length) + " 件商品")]), _c('div', {
    staticClass: "title_rule",
    on: {
      "click": _vm.showrule
    }
  }, [_c('span', [_vm._v("返利规则")]), _c('img', {
    attrs: {
      "src": require("@images/rule.png")
    }
  })])]), _c('div', {
    staticClass: "rebate-data"
  }, [_c('div', {
    staticClass: "rebate-data-item"
  }, [_c('span', {
    staticClass: "le"
  }, [_vm._v("总价：")]), _c('span', {
    staticClass: "ri"
  }, [_vm._v("￥" + _vm._s(_vm.data.rebateAmount))])]), _c('div', {
    staticClass: "rebate-data-item"
  }, [_c('span', {
    staticClass: "le"
  }, [_vm._v("活动价：")]), _c('span', {
    staticClass: "ri"
  }, [_vm._v("￥" + _vm._s(_vm.data.rebateDiscountAmount))])]), _c('div', {
    staticClass: "rebate-data-item"
  }, [_c('span', {
    staticClass: "le"
  }, [_vm._v("返利比例：")]), _c('span', {
    staticClass: "ri"
  }, [_vm._v(_vm._s(_vm.data.rebatePercent) + "%")])]), _c('div', {
    staticClass: "rebate-data-item"
  }, [_c('span', {
    staticClass: "le"
  }, [_vm._v("返利金额：")]), _c('span', {
    staticClass: "ri"
  }, [_vm._v("￥" + _vm._s(_vm.giftAmount))])])])]), _c('div', {
    staticClass: "order-detail-list"
  }, [_vm._m(0), _c('div', {
    staticClass: "product-list"
  }, [_c('div', {
    staticClass: "product-columns"
  }, [_c('p', {
    staticClass: "columns-product"
  }, [_vm._v("商品")]), _c('p', {
    staticClass: "columns-price"
  }, [_vm._v("单价")]), _c('p', {
    staticClass: "columns-num"
  }, [_vm._v("订单数量")]), _c('p', {
    staticClass: "columns-subtotal"
  }, [_vm._v("小计")]), _vm.data.orderStatus == '03' || _vm.data.orderStatus == '04' ? _c('p', {
    staticClass: "product-practical"
  }, [_vm._v(" 发货数量 ")]) : _vm._e(), _c('p', {
    staticClass: "columns-handle"
  }, [_vm._v("操作")])]), _c('div', {
    staticClass: "shopp-product-list-box"
  }, _vm._l(_vm.list, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "shopp-product"
    }, [_c('div', {
      staticClass: "product-info"
    }, [_c('div', {
      staticClass: "product-info-main"
    }, [_c('div', {
      staticClass: "product-img-box"
    }, [_c('div', {
      staticClass: "product-img"
    }, [_c('img', {
      attrs: {
        "src": item.productPhoto,
        "alt": ""
      }
    })]), item.isZeroPriceDifference == 1 ? _c('div', {
      staticClass: "zero-price-img"
    }, [_c('img', {
      attrs: {
        "src": require("@images/PC0.png")
      }
    })]) : _vm._e(), item.productAttribute == '1' ? _c('div', {
      staticClass: "product-hbimg-jf"
    }, [_vm._v(" 返利 ")]) : _vm._e()]), _c('div', {
      staticClass: "product-introduce"
    }, [_c('p', [_vm._v("【" + _vm._s(item.specifications) + "】" + _vm._s(item.productName))]), _c('p', [_vm._v(_vm._s(item.produceFactory))]), item.nearEffectivePeriod ? _c('p', [_vm._v(" 效期 " + _vm._s(_vm._f("formatDate")(item.nearEffectivePeriod)) + " ")]) : _c('p', [_vm._v("效期")]), _c('p', {
      staticClass: "lastTime"
    }, [_vm._v("最后退货时间:" + _vm._s(item.lastReturnTime))])])])]), _c('div', {
      staticClass: "product-price"
    }, [_c('p', [_vm._v(" ¥" + _vm._s(_vm._f("NumFormat")(Number(item.unitPrice))) + " "), item.sellUnit ? _c('span', [_vm._v("/" + _vm._s(item.sellUnit))]) : _vm._e()])]), _c('div', {
      staticClass: "product-num"
    }, [_c('p', [_vm._v(" " + _vm._s(item.totalNumber) + " "), item.tipsStr ? _c('span', [_vm._v("中包装")]) : _vm._e()]), item.tipsStr ? _c('span', {
      staticClass: "num"
    }, [_vm._v("(实际购买" + _vm._s(_vm._f("unit")(item.tipsStr)) + _vm._s(item.sellUnit) + ")")]) : _vm._e()]), _c('div', {
      staticClass: "product-subtotal"
    }, [_c('p', {
      staticClass: "subtotal-first"
    }, [_vm._v("¥" + _vm._s(item.subtotalMoney))]), item.preferentialTotalMoney != null ? _c('p', {
      staticClass: "subtotal-last"
    }, [_vm._v(" 优惠¥" + _vm._s(item.preferentialTotalMoney) + " ")]) : _vm._e()]), _vm.data.orderStatus == '03' || _vm.data.orderStatus == '04' ? _c('div', {
      staticClass: "md-product-practical"
    }, [_vm._v(" " + _vm._s(item.deliveryNumber) + " ")]) : _vm._e(), _c('div', {
      staticClass: "product-handle"
    }, [_c('p', {
      on: {
        "click": function click($event) {
          return _vm.addCart(item);
        }
      }
    }, [_vm._v("加入购物车")]), _c('b-tooltip', {
      attrs: {
        "position": "is-bottom",
        "multilined": "",
        "type": "is-light",
        "triggers": ['click'],
        "auto-close": ['outside', 'escape']
      },
      scopedSlots: _vm._u([{
        key: "content",
        fn: function fn() {
          return [_c('div', {
            staticClass: "tip-item"
          }, [_c('b', {
            staticStyle: {
              "width": "50%"
            }
          }, [_vm._v("生产批号")]), _c('b', {
            staticStyle: {
              "width": "50%"
            }
          }, [_vm._v("数量")])]), _vm._l(item.orderLineBatchVos, function (list, listIndex) {
            return _c('div', {
              key: listIndex,
              staticClass: "tip-item"
            }, [_c('b', {
              staticStyle: {
                "width": "50%"
              }
            }, [_vm._v(_vm._s(list.productionBatchNumber))]), _c('b', {
              staticStyle: {
                "width": "50%"
              }
            }, [_vm._v(_vm._s(list.batchDeliveryNumber))])]);
          }), item.orderLineBatchVos.length == 0 ? _c('div', {
            staticStyle: {
              "margin": "10px 0"
            }
          }, [_vm._v(" 暂无数据 ")]) : _vm._e()];
        },
        proxy: true
      }], null, true)
    }, [_c('div', {
      staticClass: "lookDetails"
    }, [_c('p', [_vm._v("查看详情")]), _c('div', {
      staticClass: "corner"
    })])])], 1)]);
  }), 0)])]), _vm.popupStatus ? _c('div', {
    staticClass: "loding"
  }, [_c('div', {
    staticClass: "loding-wrap"
  }, [_c('div', {
    staticClass: "loding-title"
  }, [_vm._v("申请开票")]), _c('div', {
    staticClass: "form"
  }, [_c('b-field', {
    attrs: {
      "horizontal": "",
      "label": "发票抬头",
      "type": _vm.danger ? 'is-danger' : '',
      "message": _vm.dangerMessage ? '不能为空' : ''
    }
  }, [_c('b-input', {
    attrs: {
      "name": "subject",
      "placeholder": "请输入",
      "expanded": "",
      "icon-right": "close-circle",
      "icon-right-clickable": ""
    },
    on: {
      "blur": _vm.blurDanger,
      "icon-right-click": _vm.clearIconClick
    },
    model: {
      value: _vm.info.rise,
      callback: function callback($$v) {
        _vm.$set(_vm.info, "rise", $$v);
      },
      expression: "info.rise"
    }
  }), _c('div', {
    staticClass: "form-management",
    on: {
      "click": _vm.handleManagement
    }
  }, [_vm._v(" 管理发票抬头 ")])], 1), _c('b-field', {
    attrs: {
      "horizontal": "",
      "label": "纳税人识别号",
      "type": _vm.duty ? 'is-danger' : '',
      "message": _vm.dutyMessage ? '请输入正确税号' : ''
    }
  }, [_c('b-input', {
    attrs: {
      "name": "subject",
      "expanded": "",
      "placeholder": "请输入"
    },
    on: {
      "blur": _vm.blurDuty
    },
    model: {
      value: _vm.info.duty,
      callback: function callback($$v) {
        _vm.$set(_vm.info, "duty", $$v);
      },
      expression: "info.duty"
    }
  })], 1), _c('b-field', {
    staticClass: "textareas",
    attrs: {
      "horizontal": "",
      "label": "备注"
    }
  }, [_c('b-input', {
    attrs: {
      "type": "textarea"
    },
    model: {
      value: _vm.info.remark,
      callback: function callback($$v) {
        _vm.$set(_vm.info, "remark", $$v);
      },
      expression: "info.remark"
    }
  })], 1), _c('div', {
    staticClass: "mode"
  }, [_c('p', {
    staticClass: "mode-title"
  }, [_vm._v("开票方式")]), _vm._l(_vm.radioList, function (item) {
    return _c('b-radio', {
      key: item.id,
      attrs: {
        "name": "电子发票",
        "type": "is-info",
        "native-value": item.value
      },
      model: {
        value: _vm.radio,
        callback: function callback($$v) {
          _vm.radio = $$v;
        },
        expression: "radio"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  })], 2), _c('div', {
    staticClass: "buttons"
  }, [_c('span', {
    staticClass: "form-cancel",
    on: {
      "click": _vm.handleCancel
    }
  }, [_vm._v("取消")]), _c('span', {
    staticClass: "form-confirm",
    on: {
      "click": _vm.handleConfirm
    }
  }, [_vm._v("确定")])])], 1)])]) : _vm._e(), _vm.isDownload ? _c('div', {
    staticClass: "download-loding"
  }, [_c('div', {
    staticClass: "download-loding-wrap"
  }, [_c('div', {
    staticClass: "download-loding-title"
  }, [_vm._v("下载商品检验报告")]), _c('div', {
    staticClass: "form"
  }, [_c('div', {
    staticClass: "means-list"
  }, [_vm._m(1), _c('ul', _vm._l(_vm.formList, function (item, index) {
    return _c('li', {
      key: index,
      staticClass: "means-item clearfix"
    }, [_c('b-checkbox', {
      attrs: {
        "type": "is-info"
      },
      on: {
        "input": function input($event) {
          return _vm.getSelectItem($event, item);
        }
      },
      model: {
        value: item.isCheckedName,
        callback: function callback($$v) {
          _vm.$set(item, "isCheckedName", $$v);
        },
        expression: "item.isCheckedName"
      }
    }), _c('span', {
      staticClass: "name"
    }, [_vm._v(_vm._s(item.productName))]), _c('span', {
      staticClass: "name"
    }, [_vm._v(_vm._s(item.specifications))]), _c('span', {
      staticClass: "name"
    }, [_vm._v(_vm._s(item.produceFactory))]), _c('span', {
      staticClass: "name"
    }, [_vm._v(_vm._s(item.batchNumber || "--"))]), _c('span', {
      staticClass: "downFlag"
    }, [_vm._v(_vm._s(item.isDownload ? "已下载" : "未下载"))])], 1);
  }), 0)])]), _c('div', {
    staticClass: "all"
  }, [_c('p', [_c('b-checkbox', {
    attrs: {
      "size": "is-small",
      "type": "is-info"
    },
    on: {
      "input": _vm.getSelectAll
    },
    model: {
      value: _vm.selectAll,
      callback: function callback($$v) {
        _vm.selectAll = $$v;
      },
      expression: "selectAll"
    }
  }, [_vm._v("全选")])], 1), _c('span', {
    staticClass: "text"
  }, [_vm._v("共" + _vm._s(_vm.arr.length) + "个文件，总计" + _vm._s(_vm.checkedAllSize) + "M")])]), _c('div', {
    staticClass: "buttons"
  }, [_c('span', {
    staticClass: "form-cancel",
    on: {
      "click": _vm.handleCancelDownload
    }
  }, [_vm._v("取消")]), _vm.hasChecked ? _c('span', {
    staticClass: "form-confirm",
    on: {
      "click": _vm.handleConfirmDownload
    }
  }, [_vm._v("确定")]) : _c('span', {
    staticClass: "form-confirm-den"
  }, [_vm._v("确定")])])])]) : _vm._e(), _vm.isshowrule ? _c('div', {
    staticClass: "fd-rules",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.closerule.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "fd-rule",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.showrule.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "fd-close",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.closerule.apply(null, arguments);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/close_icon.svg"),
      "alt": ""
    }
  })]), [_c('div', {
    staticClass: "fd-rule1"
  }, [_vm._v("返利规则")]), _c('div', {
    staticClass: "fd-rule2",
    staticStyle: {
      "margin-bottom": "0",
      "padding": "10px 0"
    }
  }, [_vm._v("单笔订单满299元，可享整单返利3%-7%.选购“返利”标签的品种越多，返利越多。次日到账。")])]], 2)]) : _vm._e()]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "detail-list-title"
  }, [_c('div', [_vm._v("商品信息")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "means-title"
  }, [_c('p'), _c('p', {
    staticClass: "ptitle"
  }, [_vm._v("商品名称")]), _c('p', {
    staticClass: "ptitle"
  }, [_vm._v("规格")]), _c('p', {
    staticClass: "ptitle"
  }, [_vm._v("生产厂家")]), _c('p', {
    staticClass: "ptitle"
  }, [_vm._v("生产批号")]), _c('p')]);
}];
export { render, staticRenderFns };